import moment from "moment";

export const parseDate = (dateStr: string, dateFormat: string): Date => {
  const date = moment(dateStr, dateFormat);
  if (date.isValid()) return date.toDate();
  else return moment().toDate();
};
//检查日期字符串
function isDateString(str:string) {
  // 尝试将字符串转换为日期对象
  const date = new Date(str);
  // 检查解析结果是否有效
  return !isNaN(date.getTime());
}
//检查数字字符串
function isNumeric(str:string) {
  return /^[0-9]+$/.test(str);
}
//初始值处理，统一为指定格式的字符串
export const formatDate = (dateStr: string, dateFormat: string): string => {
  let ret = '';
  if (dateStr) {
    let str:any = '';
    if(isDateString(dateStr)){
      str = dateStr;
    }
    if(isNumeric(dateStr)){
      str = parseInt(dateStr, 10);
    }
    const date = moment(str);
    if (date.isValid()) {
      ret = moment(date).format(dateFormat);
    }
  }
  return ret;
};
