import {NormalizedTable} from "@byk/pages/QueryBuilder/lib/metadata/types/schema";
import Field from "@byk/pages/QueryBuilder/lib/metadata/Field";

interface Table {
  name: any;
  fields: Field[],
  metadata?: any,
  alias?: string,
  segments?: any[],
  metrics?: any[],
}

class Table {
  private readonly _plainObject: any;

  constructor(table: NormalizedTable) {
    this._plainObject = table;
    Object.assign(this, table);
  }

  getPlainObject(): NormalizedTable {
    return this._plainObject;
  }

  getFields(joinAlias?: string): Field[] {
    if (!this.fields) {
      this.fields = [];
      let properties: any[] = this._plainObject['properties'];
      this.alias = joinAlias;
      if (properties) {
        properties.forEach(item => this.fields.push(new Field({...item, "metadata": this.metadata}, this)));
      }
    }

    return this.fields;
  }

  dateFields() {
    return this.getFields().filter(field => field.isDate());
  }

  // FIELDS
  fieldsLookup() {
    return Object.fromEntries(this.getFields().map(field => [field.id, field]));
  }

  numFields(): number {
    return this.fields?.length || 0;
  }

  foreignTables(): Table[] {
    const fields = this.getFields();
    if (!fields) {
      return [];
    }
    return fields
      .filter(field => field.isFK() && field.fk_target_field_id)
      .map(field => this.metadata?.field(field.fk_target_field_id)?.table)
      .filter(Boolean) as Table[];
  }

  primaryKeys(): { field: Field; index: number }[] {
    const pks: { field: Field; index: number }[] = [];
    this.getFields().forEach((field, index) => {
      if (field.isPK()) {
        pks.push({field, index});
      }
    });
    return pks;
  }

  clone() {
    const table = new Table(this.getPlainObject());
    Object.assign(table, this);
    return table;
  }

  dimensions() {
    return this.getFields().map(field => field.dimension());
  }
}

export default Table;
