import _ from "lodash";
import moment from "moment";

interface RegType {
  res2DataResult: Array<any>,
  keyMap: Record<string, any>,
  widgetTypeMap: Record<string, string>
}
const reWidgetKey = (key:string)=>{
  return [key, `${key}_LABEL`, `${key}_VALUE`];
}
export default function setWidgetValue(regs:RegType):Array<any>{
  let res2DataResultHandle:Array<any> = [];
  const {res2DataResult, keyMap, widgetTypeMap} = regs;
  _.each(res2DataResult, (i:any, index:number)=>{
    res2DataResultHandle[index] = {};
    _.each(i, (value:any, key:any)=>{
      if(keyMap[key]){
        if(Object.keys(keyMap).includes(key) && value == null){
          return true;
        }
        let cacheValue:any;
        // console.log('--');
        // console.log(key);
        // console.log(widgetTypeMap);
        // console.log(widgetTypeMap[key]);
        if(reWidgetKey('SWITCH_GROUP_WIDGET').includes(widgetTypeMap[key])
        ||reWidgetKey('MULTI_SELECT_WIDGET_V2').includes(widgetTypeMap[key])){
          cacheValue = value.split?value.split(','):value;
        }else if(reWidgetKey('DATE_PICKER_WIDGET2').includes(widgetTypeMap[key])&&value!==''){
          let dateNum = value;
          if(_.isString(value)){
            dateNum = parseInt(value, 10);
          }
          cacheValue = moment(dateNum).format('YYYY-MM-DD HH:mm:ss');
        }else if(reWidgetKey('RANGEDATEPICKER_WIDGET').includes(widgetTypeMap[key])
          &&_.isString(value)
          &&value!==''
          &&value.includes('~')){
          let dateArr:Array<any> = value.split('~');
          dateArr = dateArr.map((item:any)=>{
            if(typeof item === 'string'&&item.includes('-')){
              return item;
            }
            return parseInt(item, 10);
          });
          let dateStr1:string = moment(dateArr[0]).format('YYYY-MM-DD HH:mm:ss');
          let dateStr2:string = moment(dateArr[1]).format('YYYY-MM-DD HH:mm:ss');
          cacheValue = `${dateStr1}~${dateStr2}`;
        }else if("UPLOAD_WIDGET" === widgetTypeMap[key]){
          cacheValue = value?JSON.parse(value):[];
        }else if("UPLOAD_IMAGE_WIDGET" === widgetTypeMap[key]){
          cacheValue = value?JSON.parse(value):[];
        }else{
          cacheValue = value;
        }
        //console.log('cacheValue', cacheValue);
        if(keyMap[key].endsWith('.label')||keyMap[key].endsWith('.value')){
          //带label或value的返回，把指定值回写到组件上。把字符串转了一层数组。组件处理2维的情况下只有1维
          if(cacheValue==''){ return ;}
          let _vv:any = cacheValue;
          if(_.isString(_vv)){
            _vv = _vv.split(',');
          }else if(_.isArray(_vv)){
            _vv = _vv[0].split(',');
          }
          res2DataResultHandle[index][keyMap[key].split('.')[0]] =
            (reWidgetKey('RADIO_GROUP_WIDGET').includes(widgetTypeMap[key]))?_vv.join(''):_vv;
        }
        if(!res2DataResultHandle[index][keyMap[key]]){
          res2DataResultHandle[index][keyMap[key]] = key==='id'?cacheValue?.toString():cacheValue;
        }

      }
    })
  })
  return res2DataResultHandle;
}
