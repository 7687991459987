import {ReduxActionTypes} from "@appsmith/constants/ReduxActionConstants";
import {all, call, put, select, takeLatest, delay} from "redux-saga/effects";
import showAlertSaga from "../../../sagas/ActionExecution/ShowAlertActionSaga";
import _ from "lodash";
import type {TypeOptions} from "react-toastify";
import navigateActionSaga from "../../../sagas/ActionExecution/NavigateActionSaga";
import {NavigationTargetType} from "../../../workers/Evaluation/fns/navigateTo";
import {closeModalSaga, openModalSaga} from "../../../sagas/ActionExecution/ModalSagas";
import {reEventsActions} from "../../../sagas/ActionExecution/StoreActionSaga";
import {getAppData, getCanvasWidgets} from "../../../selectors/entitiesSelector";
import {CanvasWidgetsReduxState} from "../../../reducers/entityReducers/canvasWidgetsReducer";
import {downLoadClick} from "@byk/utils/Utils";
import {EventType} from "../../../constants/AppsmithActionConstants/ActionConstants";
import {evaluateAndExecuteDynamicTrigger} from "../../../sagas/EvaluationsSaga";
import {X_IMPORT_EXCEL} from "@byk/store/XReducers";
import getWidgetValue from "./GetWidgetValue";
import {getDataTree, getPageListMap} from "../../../selectors/dataTreeSelectors";
import {syncBatchUpdateWidgetMetaProperties, triggerEvalOnMetaUpdate} from "../../../actions/metaActions";
import widgetToValue from "./widgetToValue";
import log from "loglevel";

function* showAlertApi({ message, type }:{ message:string, type:TypeOptions }){
  yield call(showAlertSaga,{
    type: 'SHOW_ALERT',
    payload: {
      message: message,
      style: type
    }
  });
}
function* navigateToApi({
navigatePage,
navigateBlank=NavigationTargetType.SAME_WINDOW,
navigateParams,
                          navigatePageType,
                          navigateURL
}:{
  navigatePage: string,
  navigateParams: Record<string, string>,
  navigateBlank:NavigationTargetType,
  navigatePageType: string,
  navigateURL: string
}){
  yield call(navigateActionSaga, {
    type: "NAVIGATE_TO" as const,
    payload: {
      pageNameOrUrl: navigatePageType === "false"? navigateURL : navigatePage,
      params: navigateParams,
      target: navigateBlank
    },
  });
}
function* navigateUrlApi(config:any, widgetTree:any){
  console.log('navigateUrlApi--', config, widgetTree);
  let url = config.navigateURL;
  try{
    const regx = /\{([^{}]+)\}/g;
    const regxResult = url.match(regx);
    if(regxResult&&regxResult.length>0){
      _.each(regxResult, (i:any)=>{
        url = url.replace(i, eval(i));
      })
    }
  }catch(e){}
  console.log('url>>', url);
  yield call(navigateToApi, {
    ...config,
    navigatePageType: 'false',
    navigateURL: url
  });
}
function* showModalApi({modalName}:{modalName: string}){
  yield call(openModalSaga, {
    type: "SHOW_MODAL_BY_NAME" as const,
    payload: { modalName }
  });
}
function* closeModalApi({modalName}:{modalName: string}){
  yield call(closeModalSaga, {
    type: "CLOSE_MODAL" as const,
    payload: { modalName },
  });
}

function* api(config:any, widgetTree:any, globalContext:any={}) {
  //组件树转为参数;
  let pageParams:Record<string, unknown> = yield call(getWidgetValue, widgetTree);
  const {apiParams, action, apiTarget} = config;
  const result:Record<string, any> = yield call(reEventsActions, { ...config }, {
    apiParams: {
      ...pageParams,
      ...apiParams
    },
    eventParams: {
      ...apiParams
    },
    globalContext
  });
  if(_.isEmpty(result)){ return ; }
  if(action=="exported"){
    downLoadClick(result,Date.now()+".xlsx");
    return ;
  }
  const {
    data:widgetData,
    size:widgetDataSize,
    success,
    message,
    widgetName,
    widgetId,
    event,
    widgetTypeMap,
    serviceCode
  } = result;
  const {type:widgetType} = widgetTree?.[widgetName]||{};

  try{
    if(!serviceCode){
      let _widgetProp:any = widgetToValue({
        widgetTypeMap: widgetType,
        widgetType,
        key: widgetName,
        event,
        widgetTree,
        widgetId,
        widgetData,
        widgetDataSize
      });
      log.debug(`${widgetName}*${widgetType}*mock*没有匹配widgetToValue`);
      yield put(syncBatchUpdateWidgetMetaProperties(_widgetProp));
    }
    if(serviceCode&&['get'].includes(action)){
      let updateWidget:any = [];
      _.each(widgetTypeMap, (value, key)=>{
        let _widgetName:string = key;
        let _widgetProp:any = widgetToValue({
          widgetTypeMap: value,
          widgetType,
          key,
          event,
          widgetTree,
          widgetId,
          widgetData,
          widgetDataSize
        });
        if(_.isEmpty(_widgetProp)){
          log.debug(`${_widgetName}*${widgetType}*${value}*没有匹配widgetToValue`);
          return true;
        }
        updateWidget = updateWidget.concat(_widgetProp);
      })
      updateWidget = _.uniqWith(updateWidget, _.isEqual);//去重
      log.debug('updateWidget>get>>', updateWidget);
      if(_.isEmpty(updateWidget)){
        log.debug(`无输出数据`);
        return ;
      }
      yield put(syncBatchUpdateWidgetMetaProperties(updateWidget));

      yield delay(1000);
      yield put({
        type: ReduxActionTypes.BATCHED_UPDATE,
        payload: {
          type: ReduxActionTypes.META_UPDATE_DEBOUNCED_EVAL,
          payload: {
            priority: 0,
            needsSaga: false,
          }
        }
      })
      //yield call(triggerEvalOnMetaUpdate);
    }
    if(!serviceCode&&['dict'].includes(action)){
      log.debug('updateWidget>dict>>', [
        {
          widgetId,
          propertyName: 'options',
          propertyValue: widgetData
        }
      ])
      yield put(syncBatchUpdateWidgetMetaProperties([
        {
          widgetId,
          propertyName: 'options',
          propertyValue: widgetData
        }
      ]));
    }

  }catch (e) {
    console.log('输出映射错误', e);
  }

  //接口返回，弹出提示信息
  if(!success&&message){
    yield call(showAlertApi, {
      message,
      type: 'error'
    })
  }
  //接口成功并且非get
  if(success&&message&&config.action!='get'){
    yield call(showAlertApi, {
      message,
      type: 'success'
    })
  }
  //接口返回成功并且配置了成功回调
  if(success&&config.success&&config.success.children){
    yield call(xExecuteActionTriggers, {
      payload: {
        dynamicString: config.success
      }
    })
  }
  //接口返回失败并且配置了失败回调
  if(!success&&config.fail&&config.fail.children){
    yield call(xExecuteActionTriggers, {
      payload: {
        dynamicString: config.fail
      }
    })
  }
}

function* dictApi(config:any, widgetTree:any){
  //console.log('get>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}
function* getApi(config:any, widgetTree:any){
  //console.log('get>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}
function* deleteApi(config:any, widgetTree:any){
  //console.log('delete>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}
function* postApi(config:any, widgetTree:any, globalContext:any){
  //console.log('post>>>>>>>config>', config);
  yield call(api, config, widgetTree, globalContext);
}
function* putApi(config:any, widgetTree:any){
  //console.log('put>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}
function* linkageApi(config:any, widgetTree:any){
  //console.log('linkage', config);
  const canvasWidget:CanvasWidgetsReduxState = yield select(getCanvasWidgets);
  const { linkageId, linkageEvent } = config;
  if(!canvasWidget[linkageId]){
    return ;
  }
  yield call(xExecuteActionTriggers, {
    payload: {
      dynamicString: canvasWidget[linkageId][linkageEvent],
      envWidgetTree: widgetTree
    }
  })

}

function* importedApi(config:any){
  //console.log('importedApi>>>>>>>config>', config);
  yield put({
    type: X_IMPORT_EXCEL,
    data: {
      visible: true,
      config
    }
  })
}
function* exportedApi(config:any, widgetTree:any){
  //console.log('exportedApi>>>>>>>config>', config);
  yield call(api, config, widgetTree);
}
function* jsApi(config:any){
  //console.log('jsApi>>>>>>>config>', config);
  const jsStr = config.jsPath.replace(/,/g, '.');
  yield call(
    evaluateAndExecuteDynamicTrigger,
    `{{${jsStr}(appsmith.store.key${config.apiTarget});}}`,
    EventType.ON_CLICK
  );
  yield put({
    type: ReduxActionTypes.EXECUTE_PAGE_LOAD_ACTIONS
  })
}

const keyToValue = ['==', '!=', '>', '<', '>=', '<=', '6', '7', '8', '9'];
//6包含 7不包含 8为空 9不为空

const fn = (da:any, widgetTree:any, appsmith:any, globalContext:any, pageListMap:any = {})=>{
  let reJsStrFn = (data:any)=>{
    let jsStr = '';
    _.each(data, (i)=>{
      if(i.type == "block"){
        let {type, values, actionConfig:config, changeStatus, exp} = i.children[0];
        if(type == 'if'){
          _.each(i.children, j=>{
            if(j.type == "else"){
              jsStr += `else{
						  ${reJsStrFn(j.children)}
						}`;
            }else{
              let ifStr = "";
              if(changeStatus&&changeStatus.value === true){//表达式
                let v = exp?.value;
                if(v){
                  let arr1:any = v.split('&&');
                  arr1 = arr1.map((i:any)=>{
                    return `widgetTree.${i}`;
                  });
                  arr1 = arr1.join('&&');
                  ifStr = arr1;
                }
              }else{
                if(!_.isEmpty(values)){
                  _.each(values, (value, key)=>{
                    ifStr += ifStr==""?"(":"||(";
                    _.each(value, (k)=>{
                      if(!ifStr.endsWith('(')){
                        ifStr += "&&"
                      }
                      let kaStr = '';
                      if (Array.isArray(k.a)) {
                        if (k.a.length == 1) {
                          kaStr = `widgetTree.${k.a[0]}.getValue`;
                        } else if (k.a.length > 1 ) {
                          kaStr = `widgetTree.${k.a[k.a.length - 1]}`;
                        }
                      } else {
                        kaStr = `widgetTree.${k.a}.getValue`;
                      }

                      if(k.b == '6'){
                        ifStr = `${kaStr}.includes("${k.c}"`;
                      }else if(k.b == '7'){
                        ifStr += `!${kaStr}.includes("${k.c}"`;
                      }else if(k.b == '8'){
                        ifStr += `${kaStr}==""`;
                      }else if(k.b == '9'){
                        ifStr += `${kaStr}!=""`;
                      }else{
                        ifStr += `${kaStr}${keyToValue[k.b]}"${k.c}"`;
                      }
                    })
                    ifStr += ")";
                  })
                }
              }
              jsStr += `${j.type == 'elseIf'?'else if':j.type}(${ifStr}){
						  ${reJsStrFn(j.children)}
						}`;
            }
          })
        }
        if(type == 'action'){
          let navigatePage = pageListMap[config.navigatePage]?.pageName || config.navigatePage;
          jsStr += `yield call(${config.action}Api, {
            "message":"${config.message}",
            "type": "${config.type}",
            "action": "${config.action}",
            "actionId": "${config.actionId}",
            "dictId": "${config.dictId}",
            "navigateBlank": "${config.navigateBlank}",
            "navigatePage": "${navigatePage}",
            "navigatePageType": "${config.navigatePageType}",
            "navigateURL": "${config.navigateURL}",
            "navigateParams": ${config.navigateParams},
            "modalName": "${config.modalName}",
            "apiTarget": "${config.apiTarget}",
            "apiParams": ${config.apiParams||"{}" },
            "apiDescription": "${config.apiDescription}",
            "linkageId": "${config.linkageId}",
            "linkageEvent": "${config.linkageEvent}",
            "jsPath": "${config.jsPath}",
            "success": ${JSON.stringify(i.children[1])},
            "fail": ${JSON.stringify(i.children[2])},
            "currPage": widgetTree.${globalContext?.widgetName}?.currPage,
            "pageSize": widgetTree.${globalContext?.widgetName}?.pageSize
          }, widgetTree, globalContext);`;
        }
      }
    });
    return jsStr;
  }
  let jsStr = reJsStrFn(da);
  console.log('jsStr>1>', jsStr);
  const funList =
    [appsmith, widgetTree, globalContext, showAlertApi, navigateToApi, call, showModalApi, closeModalApi, getApi, deleteApi, postApi,putApi, linkageApi, navigateUrlApi, importedApi,exportedApi, jsApi, dictApi];
  const funListStr =
    ['appsmith','widgetTree', 'globalContext', 'showAlertApi', 'navigateToApi', 'call', 'showModalApi', 'closeModalApi', 'getApi', 'deleteApi', 'postApi', 'putApi', 'linkageApi', 'navigateUrlApi', 'importedApi','exportedApi', 'jsApi', 'dictApi'];
  const jsFunction = new Function(...funListStr,  `
    function* generate(){
      ${jsStr}
    }
    return [...generate()];
  `);
  return jsFunction(...funList);
};
export function* xExecuteActionTriggers(action:any): any {
  let response: unknown[] = [];

  const {dynamicString, envWidgetTree, globalContext} = action.payload;
  const js = dynamicString.children;
  let widgetTree = yield select(getDataTree);
  let pageListMap = yield select(getPageListMap);

  //console.log(`widgetTree>>>`, widgetTree);
  //在事件序列里的上下文。
  if(envWidgetTree){
    widgetTree = envWidgetTree;
  }
  const appsmith:{} = yield select(getAppData);
  yield* fn(js, widgetTree, appsmith, globalContext, pageListMap);
  return response;
}

export function* watchXActionExecutionSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.X_ACTION_EXECUTION,
      xExecuteActionTriggers
    ),
  ]);
}
