import { JSEncrypt } from "jsencrypt";

const encryptedData = (data: any) => {
    let encryptor = new JSEncrypt();
    let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChULFF3NMp7zLTPvzeR7jRv5K0MJkVHi/iHMO19zEug5HJjfyEe2wQiSWtYSlKLrSn48DA4S4Q04XVwAZQ4LbZ/cznAqX+O7zj5GMZOntJ9cb4dToZjusFne4kjrxhgFZWpj92L2kxYrSPIHOrKngtIx1zAtHKDW63To3rIunn4QIDAQAB";
    encryptor.setPublicKey(publicKey);
    return encryptor.encrypt(data);
};


export default encryptedData;