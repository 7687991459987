import {  DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popconfirm, message } from 'antd';
import React, {useRef, useState} from 'react';
import Api from "@byk/api/Api";
import { apiPath } from '@byk/routes';
import { getTenant } from 'utils/localStorage';

const UploadPic = (props:any) => {
  const {logo, onEmit,onEmitClear} = props;
  const [loading, setLoading] = useState(false);
  const [imageCode, setImageCode] = useState(logo);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadChange = (ev: any) => {
    let files = ev.target.files;
    if (files[0]) {
      const isLt2M = files[0].size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(`上传图片大小不能超过 2MB!`);
        return false;
      }
      let name = files[0]['name'];
      let ext = '';
      ext = name.substring(name.lastIndexOf('.'));
      if(ext === '.png'||ext==='.jpg'||ext==='.jpeg'){
        uploadPic(files[0]);
      }else{
        message.error('只能上传png、jpg、jpeg格式图片！');
      }
    }
  };
  const clearFile=()=>{
    setImageCode(null);
    onEmitClear();
    if(fileInputRef.current){
      fileInputRef.current.value = '';
    }
  }

  const uploadPic = (file:any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e:any) => {
      let base64 = e.target['result'];
      base64 = base64.split('base64,')[1];
      let params = {
        data: base64,
        ext: file.name.substring(file.name.lastIndexOf('.')),
        filename: file.name,
        type: file.type,
      };
      setLoading(true);
      Api.post(`${apiPath}/${getTenant()}/rest/SysFile`, params).then((res:any) => {
        let code = res.result[0]['code'];
        setImageCode(code);
        onEmit&&onEmit(code);
      }).finally(()=>{
        setLoading(false);
      });
    };
  };

  return (
    <div style={{position: 'relative', height: '60px', backgroundColor: '#f7f7f7'}}>
      {
        imageCode?
        <Popconfirm placement="left" title={"确定删除logo吗？"} onConfirm={() => clearFile()} okText="确定" cancelText="取消">
          <DeleteOutlined style={{position:"absolute", top:"-28px", right:"0px", cursor:"pointer", zIndex:99999,color:"black"}} />
        </Popconfirm>
        :null
      }
      {
        imageCode?<img style={{maxWidth: '100%', maxHeight: '100%'}} src={`${apiPath}/${getTenant()}/file/${imageCode}`} alt="logo" />:<div style={{height:'100%', backgroundColor: '#f4f4f4'}} />
      }
      {
        loading&&<div style={{position: 'absolute', zIndex: 3, left: '50%', top: '50%', margin: '-10px 0 0 -10px'}}><LoadingOutlined /></div>
      }
      <input ref={fileInputRef} style={{width: '100%', height: '60px', opacity: '0', position: 'absolute', left: 0, top: 0, cursor: 'pointer'}} type="file" onChange={uploadChange} accept=".jpg,.jpeg,.png" />
    </div>
  );
};

export default UploadPic;
