import {get} from "lodash";
import {EVAL_VALUE_PATH} from "utils/DynamicBindingUtils";

const WidgetsEvents: any = {
  "EchartsWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "TimingWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "TableWigetAntd": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "InputWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onTextChanged",
          "label": "onTextChanged",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onFocus",
          "label": "onFocus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onBlur",
          "label": "onBlur",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "DynamicFormsWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onSubmit",
          "label": "onSubmit",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "StepsWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onStepSelected",
          "label": "onStepSelected",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onSubmit",
          "label": "onSubmit",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "ButtonWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onMouseEnter",
          "label": "onMouseEnter",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "TableWidgetV2": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          helpText: "when a table row is selected",
          propertyName: "onRowSelected",
          label: "onRowSelected",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        }
      ]
    }
  ],
  "FormWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "SearchFormWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CONTAINER_WIDGET": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "ModalWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the modal is onOpen",
          "propertyName": "onOpen",
          "label": "onOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the modal is closed",
          "propertyName": "onClose",
          "label": "onClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CheckboxWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the check state is changed",
          "propertyName": "onCheckChange",
          "label": "onCheckChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "IconButtonWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "ImageWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when user clicks on an image",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "FilePickerWidgetV2": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText":
            "when the user selects a file. Upload files to a CDN and stores their URLs in filepicker.files",
          "propertyName": "onFilesSelected",
          "label": "onFilesSelected",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "DatePickerWidget2": [
    {
      "sectionName": "Events",
      "children": [
        {
          "propertyName": "onDateSelected",
          "label": "onDateSelected",
          "helpText": "when a date is selected in the calendar",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "propertyName": "onFocus",
          "label": "onFocus",
          "helpText": "when the date picker receives focus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "propertyName": "onBlur",
          "label": "onBlur",
          "helpText": "when the date picker loses focus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "SelectWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user selects an option",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when a user selects an option",
          "propertyName": "onOptionChange",
          "label": "onOptionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown opens",
          "propertyName": "onDropdownOpen",
          "label": "onDropdownOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown closes",
          "propertyName": "onDropdownClose",
          "label": "onDropdownClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "TabsWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onTabSelected",
          "label": "onTabSelected",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "RichTextEditorWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the text is changed",
          "propertyName": "onTextChange",
          "label": "onTextChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "RadioGroupWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user changes the selected option",
          "propertyName": "onSelectionChange",
          "label": "onSelectionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "ChartWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the chart data point is clicked",
          "propertyName": "onDataPointClick",
          "label": "onDataPointClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CheckboxGroupWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the check state is changed",
          "propertyName": "onSelectionChange",
          "label": "onSelectionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "SingleSelectTreeWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user selects an option",
          "propertyName": "onOptionChange",
          "label": "onOptionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown opens",
          "propertyName": "onDropdownOpen",
          "label": "onDropdownOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown closes",
          "propertyName": "onDropdownClose",
          "label": "onDropdownClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "MultiSelectWidgetV2": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user selects an option",
          "propertyName": "onOptionChange",
          "label": "onOptionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown opens",
          "propertyName": "onDropdownOpen",
          "label": "onDropdownOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown closes",
          "propertyName": "onDropdownClose",
          "label": "onDropdownClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "MultiSelectTreeWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user selects an option",
          "propertyName": "onOptionChange",
          "label": "onOptionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown opens",
          "propertyName": "onDropdownOpen",
          "label": "onDropdownOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown closes",
          "propertyName": "onDropdownClose",
          "label": "onDropdownClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CurrencyInputWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the text is changed",
          "propertyName": "onTextChanged",
          "label": "onTextChanged",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input field receives focus",
          "propertyName": "onFocus",
          "label": "onFocus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input field loses focus",
          "propertyName": "onBlur",
          "label": "onBlur",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "on submit (when the enter key is pressed)",
          "propertyName": "onSubmit",
          "label": "onSubmit",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "SwitchGroupWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a switch state inside the group is changed",
          "propertyName": "onSelectionChange",
          "label": "onSelectionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "VideoWidget": [
    {
      "sectionName": "VideoWidget",
      "children": [
        {
          "helpText": "when the video is played",
          "propertyName": "onPlay",
          "label": "onPlay",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the video is paused",
          "propertyName": "onPause",
          "label": "onPause",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the video ends",
          "propertyName": "onEnd",
          "label": "onEnd",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "JSONFormWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "propertyName": "onSubmit",
          "helpText": "when the submit button is clicked",
          "label": "onSubmit",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "SwitchWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the switch state is changed",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "RateWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the rate is changed",
          "propertyName": "onRateChanged",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "PhoneInputWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the text is changed",
          "propertyName": "onTextChanged",
          "label": "onTextChanged",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input field receives focus",
          "propertyName": "onFocus",
          "label": "onFocus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input field loses focus",
          "propertyName": "onBlur",
          "label": "onBlur",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "NumberSliderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user changes the slider value",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CategorySliderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user changes the slider value",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "MapWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "propertyName": "onMarkerClick",
          "label": "onMarkerClick",
          "helpText": "when the user clicks on the marker",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "RangeSliderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user changes the slider value",
          "propertyName": "onStartValueChange",
          "label": "onStartValueChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when a user changes the slider value",
          "propertyName": "onEndValueChange",
          "label": "onEndValueChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "AudioWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the audio is played",
          "propertyName": "onPlay",
          "label": "onPlay",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the audio is paused",
          "propertyName": "onPause",
          "label": "onPause",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the audio ends",
          "propertyName": "onEnd",
          "label": "onEnd",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "AudioRecorderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the recording starts",
          "propertyName": "onRecordingStart",
          "label": "onRecordingStart",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the recording ends",
          "propertyName": "onRecordingComplete",
          "label": "onRecordingComplete",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CodeScannerWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a valid code is detected",
          "propertyName": "onCodeDetected",
          "label": "onCodeDetected",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "IframeWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          helpText: "when the source URL is changed",
          propertyName: "onURLChanged",
          label: "onURLChanged",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        },
        {
          helpText: "when the srcDoc is changed",
          propertyName: "onSrcDocChanged",
          label: "onSrcDocChanged",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        },
        {
          helpText: "when a message event is received",
          propertyName: "onMessageReceived",
          label: "onMessageReceived",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        },
      ]
    }
  ],
  "ListWidgetV2": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "propertyName": "onItemClick",
          "helpText": "Triggers an action when an item in this List is clicked",
          "label": "onItemClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true,
          "additionalAutoComplete": (props: any) => {
            let items = get(props, `${EVAL_VALUE_PATH}.listData`, []);

            if (Array.isArray(items)) {
              items = items.filter(Boolean);
            } else {
              items = [];
            }

            return {
              currentItem: Object.assign(
                {},
                ...Object.keys(get(items, "0", {})).map((key) => ({
                  [key]: "",
                })),
              ),
              currentIndex: 0,
            };
          },
          "dependencies": ["listData"]
        }
      ]
    }
  ],
  "TreeWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when a user changes the selected option",
          "propertyName": "onSelect",
          "label": "onSelect",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "MarqueeWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the marquee is onLoad",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CasaderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user selects an option",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when a user selects an option",
          "propertyName": "onOptionChange",
          "label": "onOptionChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown opens",
          "propertyName": "onDropdownOpen",
          "label": "onDropdownOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the dropdown closes",
          "propertyName": "onDropdownClose",
          "label": "onDropdownClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "RangeDatePickerWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when a user changes the selected option",
          "propertyName": "onSelectionChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "TextWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],

  "DRAWER_WIDGET": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClose",
          "label": "onClose",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onOpen",
          "label": "onOpen",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],

  "SliderWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the switch state is changed",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "CAROUSEL_WIDGET": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onClick",
          "label": "onClick",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
      ]
    }
  ],
  "XCollapseWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the button is clicked",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the button is clicked",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
      ]
    }
  ],
  "InputNumberWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the input value is changed",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input field loses focus",
          "propertyName": "onBlur",
          "label": "onBlur",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        },
        {
          "helpText": "when the input is clicked",
          "propertyName": "onFocus",
          "label": "onFocus",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "AmapWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the switch state is changed",
          "propertyName": "onChange",
          "label": "onChange",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  "AmapViewWidget": [
    {
      "sectionName": "Events",
      "children": [
        {
          "helpText": "when the switch state is changed",
          "propertyName": "onLoad",
          "label": "onLoad",
          "controlType": "ACTION_SELECTOR",
          "isJSConvertible": true,
          "isBindProperty": true,
          "isTriggerProperty": true
        }
      ]
    }
  ],
  
}

export default WidgetsEvents;
