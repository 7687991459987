import React, { useState } from "react";
import { ChromePicker } from 'react-color';
import { Popover } from "antd";
function SketchPicker(props:any){
  const {value, onChange} = props;
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const onChangeComplete = (color: any) => {
    onChange?.(color.hex);
  };
  return (
    <Popover style={{padding: 0}}
      content={
        <ChromePicker color={value} onChangeComplete={(color) => {
          onChangeComplete(color);
        }
        } />
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <span style={{width:'20px',height:'20px',display:'inline-block',border:'1px solid #eee',backgroundColor:value, position: 'relative', top: '3px'}} />
    </Popover>

  )
}

export default SketchPicker;
