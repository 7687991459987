import type {
  ClonePageSuccessPayload,
  Page,
  ReduxAction,
} from "@appsmith/constants/ReduxActionConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
import type {
  GenerateCRUDSuccess,
  UpdatePageErrorPayload,
} from "actions/pageActions";
import type { UpdatePageRequest, UpdatePageResponse } from "api/PageApi";
import { sortBy } from "lodash";
import type { DSL } from "reducers/uiReducers/pageCanvasStructureReducer";
import { createReducer } from "utils/ReducerUtils";
import {SetDefaultPageRequest, SetLoginPageRequest} from "../../ce/api/ApplicationApi";

const initialState: PageListReduxState = {
  pages: [],
  isGeneratingTemplatePage: false,
  applicationId: "",
  currentPageId: "",
  defaultPageId: "",
  loading: {},
};

export const pageListReducer = createReducer(initialState, {
  [ReduxActionTypes.DELETE_PAGE_INIT]: (
    state: PageListReduxState,
    action: ReduxAction<{ id: string }>,
  ) => {
    if (state.defaultPageId !== action.payload.id) {
      const pages = [
        ...state.pages.filter((page) => page.pageId !== action.payload.id),
      ];
      return {
        ...state,
        pages,
      };
    }
    return state;
  },
  [ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{ pages: Page[]; applicationId: string }>,
  ) => {
    return {
      ...state,
      ...action.payload,
      defaultPageId:
        action.payload.pages.find((page) => page.isDefault)?.pageId ||
        action.payload.pages[0].pageId,
    };
  },
  [ReduxActionTypes.UPDATE_PAGE_LIST]: (
    state: PageListReduxState,
    action: ReduxAction<
      Array<{ pageId: string; dsl: DSL; userPermissions: string[] }>
    >,
  ) => {
    const pagePermissionsMap = action.payload.reduce((acc, page) => {
      acc[page.pageId] = page.userPermissions;
      return acc;
    }, {} as Record<string, string[]>);

    return {
      ...state,
      pages: state.pages.map((page) => {
        return {
          ...page,
          userPermissions:
            pagePermissionsMap[page.pageId] ?? (page.userPermissions || []),
        };
      }),
    };
  },
  [ReduxActionTypes.RESET_PAGE_LIST]: () => initialState,
  [ReduxActionTypes.CREATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pageName: string;
      description?: string;
      pageId: string;
      layoutId: string;
      isDefault: boolean;
      slug: string;
    }>,
  ) => {
    const _state = state;
    _state.pages = state.pages.map((page) => ({ ...page, latest: false }));
    _state.pages.push({ ...action.payload, latest: true });
    return { ..._state };
  },
  [ReduxActionTypes.CLONE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<ClonePageSuccessPayload>,
  ): PageListReduxState => {
    return {
      ...state,
      pages: state.pages
        .map((page) => ({ ...page, latest: false }))
        .concat([{ ...action.payload, latest: true }]),
    };
  },
  [ReduxActionTypes.SET_DEFAULT_APPLICATION_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<SetDefaultPageRequest>,
  ) => {
    if (
      state.applicationId == action.payload.appId
    ) {
      const pageList = state.pages.map((page) => {
        if (page.pageId == action.payload.pageId && action.payload.flug) {
          page.isDefault = action.payload.flug
        }else{
          page.isDefault = false;
        }
        return page;
      });
      return {
        ...state,
        pages: pageList,
        defaultPageId: action.payload.flug?action.payload.pageId:'',
      };
    }
    return state;
  },
  [ReduxActionTypes.SET_LOGIN_APPLICATION_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<SetLoginPageRequest>,
  ) => {
    const pageList = state.pages.map((page) => {
      if (page.pageId != action.payload.pageId) page.isLoginPage = false;
      if (page.pageId == action.payload.pageId) page.isLoginPage = action.payload.flug;
      return page;
    });
    return {
      ...state,
      pages: pageList,
      defaultPageId: action.payload.pageId,
    };
  },
  [ReduxActionTypes.SWITCH_CURRENT_PAGE_ID]: (
    state: PageListReduxState,
    action: ReduxAction<{
      id: string;
      slug?: string;
      permissions?: string[];
      frameDesignPageFlag:boolean,
      backgroundColor: string,
      loyoutId:number }>,
  ) => {
    const pageList = state.pages.map((page) => {
      if (page.pageId == action.payload.id && action.payload.permissions){
        page.userPermissions = action.payload.permissions;
        page.backgroundColor = action.payload.backgroundColor;
        page.frameDesignPageFlag = action.payload.frameDesignPageFlag;
        page.loyoutId = action.payload.loyoutId;
        page.iframePage = action.payload.frameDesignPageFlag;
      }

      return page;
    });
    return {
      ...state,
      currentPageId: action.payload.id,
      pages: pageList,
    };
  },
  [ReduxActionTypes.UPDATE_PAGE_INIT]: (
    state: PageListReduxState,
    action: ReduxAction<UpdatePageRequest>,
  ) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [action.payload.id]: true,
      },
    };
  },
  [ReduxActionTypes.UPDATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<UpdatePageResponse>,
  ) => {
    const pages = [...state.pages];
    const updatedPageIndex = pages.findIndex(
      (page) => page.pageId == action.payload.id,
    );

    if (updatedPageIndex !== -1) {
      const updatedPage = {
        ...pages[updatedPageIndex],
        pageName: action.payload.name,
        isHidden: !!action.payload.isHidden,
        slug: action.payload.slug,
        customSlug: action.payload.customSlug,
        backgroundColor: action.payload.backgroundColor,
        frameDesignPageFlag: action.payload.frameDesignPageFlag,
        iframePage: action.payload.frameDesignPageFlag,
      };
      pages.splice(updatedPageIndex, 1, updatedPage);
    }

    return {
      ...state,
      pages,
      loading: {
        ...state.loading,
        [action.payload.id]: false,
      },
    };
  },
  [ReduxActionErrorTypes.UPDATE_PAGE_ERROR]: (
    state: PageListReduxState,
    action: ReduxAction<UpdatePageErrorPayload>,
  ) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        [action.payload.request.id]: false,
      },
    };
  },
  [ReduxActionTypes.GENERATE_TEMPLATE_PAGE_INIT]: (
    state: PageListReduxState,
  ) => {
    return { ...state, isGeneratingTemplatePage: true };
  },
  [ReduxActionTypes.GENERATE_TEMPLATE_PAGE_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<GenerateCRUDSuccess>,
  ) => {
    const _state = state;
    if (action.payload.isNewPage) {
      _state.pages = state.pages.map((page) => ({ ...page, latest: false }));
      const newPage = {
        pageName: action.payload.page.name,
        pageId: action.payload.page.id,
        layoutId: action.payload.page.layouts[0].id,
        isDefault: !!action.payload.page.isDefault,
        slug: action.payload.page.slug,
        description: action.payload.page.description,
      };
      _state.pages.push({ ...newPage, latest: true });
    }

    return {
      ..._state,
      isGeneratingTemplatePage: false,
    };
  },
  [ReduxActionErrorTypes.GENERATE_TEMPLATE_PAGE_ERROR]: (
    state: PageListReduxState,
  ) => {
    return { ...state, isGeneratingTemplatePage: false };
  },
  [ReduxActionTypes.SET_PAGE_ORDER_SUCCESS]: (
    state: PageListReduxState,
    action: ReduxAction<{
      pages: {
        id: string;
      }[];
    }>,
  ) => {
    const sortingOrder = action.payload.pages.map((page) => page.id);
    const sortedPages = sortBy(state.pages, (page) => {
      return sortingOrder.indexOf(page.pageId);
    });

    return { ...state, pages: sortedPages };
  },
});

export type SupportedLayouts =
  | "DESKTOP"
  | "TABLET_LARGE"
  | "TABLET"
  | "MOBILE"
  | "FLUID";

export interface AppLayoutConfig {
  type: SupportedLayouts;
}

export enum AppPositioningTypes {
  FIXED = "FIXED",
  AUTO = "AUTO",
}
export interface AppPositioningTypeConfig {
  type: AppPositioningTypes;
}

export interface PageListReduxState {
  pages: Page[];
  applicationId: string;
  defaultPageId: string;
  currentPageId: string;
  appLayout?: AppLayoutConfig;
  isGeneratingTemplatePage?: boolean;
  loading: Record<string, boolean>;
}

export default pageListReducer;
