import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import SketchPicker from "./SketchPicker";
import React, { useEffect,  } from "react";
import {borderOpt, fontWeightOpt} from './StyleOption';
import {XCheckbox, XTitle} from './TopStyles';

const LeftStyles = (props:any)=>{
  const {layout, defaultValues, onValuesChangeLeft} = props;
  const [form] = Form.useForm();
  useEffect(()=>{
    form.setFieldsValue({
      left: {
        width: '200',
        height: '30',
        fontSize: '14',
        childFontSize: '14',
        selectFontSize: '14',
        ...defaultValues.left
      }
    })
  }, [defaultValues]);
  function onValuesChange(values:any){
    onValuesChangeLeft(values);
  }
  return (
    <Form form={form} {...layout} name="left" onValuesChange={onValuesChange}>
      <Form.Item name={['left', 'width']} label="导航宽度" labelAlign="left" style={{marginBottom: 0}}>
        <Input suffix="px" />
      </Form.Item>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'isBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'backgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>背景色</Col>
      </Row>
      <Form.Item name={['left', 'height']} label="菜单高度" labelAlign={'left'} style={{margin: '10px 0 0 0'}}>
        <Input suffix="px" />
      </Form.Item>
      <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['left', 'fontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'fontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'fontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{margin: '10px 0',borderTop: '1px solid #ccc'}} />
      <XTitle>子菜单</XTitle>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'childIsBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'childBackgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>背景色</Col>
      </Row>
      <Form.Item name={['left', 'childWidth']} label="菜单高度" labelAlign={'left'} style={{margin: '10px 0 0 0'}}>
        <Input suffix="px" />
      </Form.Item>
      <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['left', 'childFontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'childFontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'childFontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{margin: '10px 0',borderTop: '1px solid #ccc'}} />
      <XTitle>选中项</XTitle>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['left', 'selectIsBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['left', 'selectBackgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>选中背景色</Col>
      </Row>
      <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['left', 'selectFontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['left', 'selectFontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['left', 'selectFontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
};
export default LeftStyles;
