import {chain, updateIn} from "icepick";
import {FieldReference, JoinObject, StructuredQueryType} from "@byk/pages/QueryBuilder/lib/metadata/types";
import Join from "@byk/pages/QueryBuilder/lib/queries/structured/Join";
import Dimension, {ExpressionDimension, FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import DimensionOptions from "@byk/pages/QueryBuilder/lib/metadata/DimensionOptions";
import Field from "@byk/pages/QueryBuilder/lib/metadata/Field";
import Table from "@byk/pages/QueryBuilder/lib/metadata/Table";
import Filter from "@byk/pages/QueryBuilder/lib/queries/structured/Filter";
import Aggregation from "@byk/pages/QueryBuilder/lib/queries/structured/Aggregation";
import Breakout from "@byk/pages/QueryBuilder/lib/queries/structured/Breakout";
import {DISPLAY_QUOTES} from "@byk/pages/QueryBuilder/lib/expressions";
import {expressionFormat} from "@byk/pages/QueryBuilder/lib/expressions/format";

type DimensionFilterFn = (dimension: Dimension) => boolean;
export type FieldFilterFn = (field: Field) => boolean;

export const getFieldReference = function (filedObj: any, alias?: any): FieldReference {
  return ["field", filedObj.id, alias];
}

class StructuredQuery {
  _datasetQuery: StructuredQueryType;
  _metadata: any;

  /**
   * Creates a new StructuredQuery based on the provided DatasetQuery object
   */
  constructor(
    datasetQuery?: StructuredQueryType,
    metadata?: any
  ) {
    this._datasetQuery = datasetQuery ? datasetQuery : {};
    this._metadata = metadata;
  }

  /**
   * @returns the table ID, if a table is selected.
   */
  modelId(): any {
    return this.dataQuery()?.["modelId"];
  }

  hasModel(): boolean {
    return !!this.modelId();
  }

  /**
   * @returns a new query with the provided Table ID set.
   */
  setModelId(modelId: any): StructuredQuery {
    if (modelId !== this.modelId()) {
      let newQuery = new StructuredQuery({
        appId: this._datasetQuery.appId,
        title: this._datasetQuery.title,
        id: this._datasetQuery.id,
        description: this._datasetQuery.description,
        modelId: modelId
      }, this._metadata);

      return newQuery;
    } else {
      return this;
    }
  }

  setFields(fields: any[]) {
    this._datasetQuery.fields = [];
    fields.forEach(item => {
      this._datasetQuery.fields?.push(getFieldReference(item));
    })
    return this;
  }

  fields() {
    return this._datasetQuery.fields || [];
  }

  hasFields() {
    return this.fields().length > 0;
  }

  /**
   * 更新Join
   * @param _index
   * @param join
   */
  updateJoin(_index: number, join: Join | JoinObject) {
    let newJoin = new Join(join, _index, this);
    let joins = this.joins() || [];
    let newJoins: any[] = [];
    if (joins.length == 0) {
      newJoins.push(newJoin);
    } else {
      let replaceFlag = false;
      joins.forEach(item => {
        if (item._index == _index) {
          newJoins.push(newJoin);
          replaceFlag = true;
        } else {
          newJoins.push(item);
        }
      })

      if (!replaceFlag) {
        newJoins.push(newJoin);
      }
    }

    this._datasetQuery.joins = [...newJoins];
    console.log("StructureQuery-updateJoin", this);
    return this;
  }

  hasJoins() {
    return this.joins().length > 0;
  }

  /**
   * 保存查询参数，获取表单信息
   * @param values
   */
  addFormValues(values: any) {
    this._datasetQuery = {
      ...this._datasetQuery,
      ...values
    }
    return this;
  }

  queries() {
    const queries = [this];
    return queries;
  }

  valid() {
    return !!this.modelId();
  }

  reset(): StructuredQuery {
    this._datasetQuery = {appId: this._datasetQuery.appId};
    return this;
  }

  setWithResult(result: any): StructuredQuery {
    this._datasetQuery = {
      ...result.structuredQueryVO,
      title: result.title,
      id: result.id,
      appId: result.appId,
      description: result.description
    }
    return this;
  }

  dataQuery(): StructuredQueryType {
    return this._datasetQuery;
  }

  setDataQuery(query: StructuredQueryType): StructuredQuery {
    return new StructuredQuery(query);
  }

  clearQuery() {
    return this._updateQuery(() => ({}));
  }

  updateQuery(
    fn: (q: StructuredQueryType) => StructuredQueryType,
  ): StructuredQuery {
    return this._updateQuery(fn, []);
  }

  /**
   * The (wrapped) source query, if any
   */
  sourceQuery(): StructuredQuery | null {
    const sourceQuery = this.dataQuery()?.["source-query"];

    if (sourceQuery) {
      return new NestedStructuredQuery(
        {...this.dataQuery()},
        this,
      );
    } else {
      return null;
    }
  }

  setSourceQuery(
    sourceQuery: StructuredQuery | StructuredQueryType,
  ): StructuredQuery {
    if (sourceQuery instanceof StructuredQuery) {
      if (this.sourceQuery() === sourceQuery) {
        return this;
      }

      sourceQuery = sourceQuery.dataQuery();
    }

    return this._updateQuery(query =>
      chain(query)
        .dissoc("modelId")
        .assoc("source-query", sourceQuery)
        .value(),
    );
  }

  /**
   * If this query is empty and there's a source-query, strip off this query, returning the source-query
   */
  cleanEmpty(): StructuredQuery {
    const sourceQuery = this.sourceQuery();

    if (sourceQuery) {
      return sourceQuery;
    } else {
      return this;
    }
  }

  /**
   * Removes empty/useless layers of nesting (recursively)
   */
  cleanNesting(): StructuredQuery {
    // first clean the sourceQuery, if any, recursively
    const sourceQuery = this.sourceQuery();

    if (sourceQuery) {
      return this.setSourceQuery(sourceQuery.cleanNesting()).cleanEmpty();
    } else {
      return this;
    }
  }

  /**
   * Returns the "first" of the nested queries, or this query it not nested
   */
  rootQuery(): StructuredQuery {
    return this;
  }

  // INTERNAL
  _updateQuery(
    updateFunction: (
      query: StructuredQueryType,
      ...args: any[]
    ) => StructuredQueryType,
    args: any[] = [],
  ): StructuredQuery {
    let dataQuery = this.setDataQuery(
      updateIn(this._datasetQuery, [], query => {
          console.log('query, args', this._datasetQuery, query, args);
          updateFunction(query, ...args)
        }
      )
    );
    return dataQuery;
  }

  joins(): Join[] {
    return this._datasetQuery.joins || [];
  }

  removeJoin(index = 0) {
    let joins = this._datasetQuery.joins || [];
    if (joins.length >= index) {
      joins.splice(index, 1);

      let reorder: Join[] = [];
      let idx = 0;
      joins.forEach((item: Join) => {
        reorder.push(item.setIndex(idx++));
      })

      this._datasetQuery.joins = reorder;
    }

    return this;
  }

  hasExpressions() {
    return this.expressions().length > 0;
  }

  clearExpressions() {
    this._datasetQuery.expressions = {};
    return this;
  }

  clearFilters() {
    this._datasetQuery.filter = undefined;
    return this;
  }

  hasAggregations() {
    return this.aggregations().length > 0;
  }

  hasBreakouts() {
    return this.breakouts().length > 0;
  }

  clearBreakouts() {
    this._datasetQuery.breakout = undefined;
    return this;
  }

  clearAggregations() {
    this._datasetQuery.aggregation = undefined;
    return this;
  }

  /**
   * @returns the table object, if a table is selected and loaded.
   */
  model(): Table | null {
    return this.getModelById(this.modelId());
  }

  getModelById(modelId: any): Table | null {
    if (!this.hasModels()) {
      return null;
    }

    let model: any;
    this._metadata.models.forEach((item: { id: any; }) => {
      if (modelId == item.id) {
        model = item;
      }
    })
    return new Table({...model, "metadata": this._metadata});
  }

  hasModels() {
    return this._metadata && this._metadata.models && this._metadata.models.length > 0;
  }

  setModels(models: any[]) {
    if (models) {
      this._metadata = {
        models,
      }
    }
  }

  models(): any[] {
    if (this.hasModels()) {
      return this._metadata.models;
    } else {
      return [];
    }
  }

  metaProperties(modelId: any): any {
    if (!this.hasModels()) {
      return null;
    }
    let ret: any[] = [];
    this._metadata.models.forEach((item: { id: any, properties: any[] }) => {
      if (modelId == item.id) {
        ret = item.properties;
      }
    })

    return ret;
  }

  metaProperty(propertyId: any): any {
    return this._metadata.properties[this.metaPropertyKey(propertyId)];
  }

  metaPropertyKey(propertyId: any): any {
    return 'p_' + propertyId;
  }

  setMetaProperties(modelId: any, properties: any[]) {
    if (!this.hasModels()) {
      return null;
    }
    if (!this._metadata.properties) {
      this._metadata.properties = {};
    }
    let model = this.getModelById(modelId);
    this._metadata.models.forEach((item: { id: any, properties: any[] }) => {
      if (modelId == item.id) {
        item.properties = properties;
        properties.forEach(p => {
          this._metadata.properties[this.metaPropertyKey(p.id)] = p;
          p['table'] = model;
        })
      }
    })
  }

  /**
   * 添加模型属性元数据
   */
  updateModelMetadata(modelMeta: any) {
    if (this.hasModels() && modelMeta && modelMeta['id']) {
      let modelId = modelMeta['id'];
      this._metadata.model[modelId] = modelMeta;
    }
    return this;
  }

  filter(newFilter: any) {
    return this.addFilter(newFilter);
  }

  topLevelFilterFieldOptionSections(): any[] {
    return [];
  }

  filterDimensionOptions(): DimensionOptions {
    return this.dimensionOptions();
  }

  filterFieldOptionSections(filter?: any) {
    const filterDimensionOptions = this.filterDimensionOptions();
    return filterDimensionOptions.sections();
  }

  parseFieldReference(fieldRef: FieldReference, query = this): FieldDimension {
    return new FieldDimension(fieldRef[1], fieldRef[2], query?._metadata, query);
  }

  tableDimensions(): Dimension[] {
    const table = this.model();
    return table
      ? table
        .dimensions()
        .map(d => (d._query ? d : this.parseFieldReference(d.mbql())))
      : [];
  }

  dimensions(): Dimension[] {
    return [...this.tableDimensions()];
  }

  dimensionOptions(dimensionFilter: DimensionFilterFn = dimension => true,): DimensionOptions {
    const dimensionOptions = {
      count: 0,
      fks: [],
      dimensions: [],
    };

    const joins = this.joins();
    for (const join of joins) {
      const joinedDimensionOptions: DimensionOptions = join.joinedDimensionOptions(dimensionFilter);
      if (joinedDimensionOptions.count > 0) {
        dimensionOptions.count += joinedDimensionOptions.count;
        // @ts-ignore
        dimensionOptions.fks.push(joinedDimensionOptions);
      }
    }

    const table = this.model();
    if (table) {
      const filteredNonFKDimensions = this.dimensions().filter(dimensionFilter);

      for (const dimension of filteredNonFKDimensions) {
        dimensionOptions.count++;
        // @ts-ignore
        dimensionOptions.dimensions.push(dimension);
      }
    }

    return new DimensionOptions(dimensionOptions);
  }

  private addFilter(filter: any) {
    let filterArr: any[] = this._datasetQuery.filter || ["and"];
    filterArr.push(filter);
    filter._index = filterArr.length - 2;
    this._datasetQuery.filter = [...filterArr];
    return this;
  }

  filters(): Filter[] {
    let retArr: Filter[] = [];
    let queryFilter: any[] = this._datasetQuery.filter || [];
    for (let i = 1; i < queryFilter.length; i++) {
      retArr.push(new Filter(queryFilter[i], i - 1, this));
    }

    return retArr;
  }

  updateFilter(_index: number, filter: any[]) {
    let filters: any[] = this.filters();
    filters[_index] = filter;
    this._datasetQuery.filter = ["and", ...filters];
    return this;
  }

  removeFilter(_index: number) {
    let filters: any[] = this.filters();
    if (filters.length > _index) {
      filters.splice(_index, 1);
    }
    if (filters.length > 0) {
      let idx = 0;
      for (let filter of filters) {
        filter._index = idx;
        idx++;
      }
      this._datasetQuery.filter = ["and", ...filters];
    } else {
      this._datasetQuery.filter = undefined;
    }
    return this;
  }

  hasFilters() {
    return this.filters().length > 0;
  }

  hasLimit() {
    return !!this._datasetQuery.limit;
  }


  limit() {
    return this._datasetQuery.limit;
  }

  updateLimit(nextLimit: number) {
    this._datasetQuery.limit = nextLimit;
    return this;
  }

  clearLimit() {
    this._datasetQuery.limit = undefined;
    return this;
  }

  hasOrderBys() {
    return !!this._datasetQuery.orderBy;
  }

  orderBys() {
    return this._datasetQuery.orderBy;
  }

  addOrderBy(field: Field, alias: string) {
    if (!this.hasOrderBys()) {
      this._datasetQuery.orderBy = [];
    }

    this._datasetQuery.orderBy?.push([alias, ["field", field.id, field.table?.alias]]);

    return this;
  }

  groupColumns() {
    let orderByArr = this.orderBys();
    let selected: any = {};
    orderByArr?.forEach(item => {
      let key = item[1][1] + "_" + (item[1][2] ? item[1][2] : "");
      selected[key] = true;
    })

    function getItems(fields: Field[]): any[] {
      let items: any[] = [];
      fields.forEach(item => {
        let key = item.id + "_" + (item.table?.alias ? item.table?.alias : "");
        if (!selected[key]) {
          items.push({
            name: item.name,
            icon: "label",
            field: item
          })
        }
      })

      return items;
    }

    let model = this.model();
    let sections = [];
    if (model) {
      sections.push({
        name: model?.name,
        icon: "table",
        items: getItems(model.getFields())
      })
    }

    let joinModels = this.joins();
    joinModels.forEach(item => {
      let model = this.getModelById(item.modelId);
      if (model) {
        sections.push({
          name: item.alias || model?.name,
          icon: "table",
          items: getItems(model.getFields(item.alias))
        });
      }
    })

    return sections;
  }

  sortDisplayName(clause: any) {
    let property = this.metaProperty(clause[1][1]);
    return {
      longDisplayName: (clause[2] || '') + property.name,
      direction: clause[0],
    }
  }

  changeDirection(clause: any) {
    let orderBysArr: any[] = [];
    this.orderBys()?.forEach(item => {
      if (item[1][0] == clause[1][0] && item[1][1] == clause[1][1] && item[1][2] == clause[1][2]) {
        clause[0] = clause[0] == "asc" ? "desc" : "asc";
        orderBysArr.push(clause);
      } else {
        orderBysArr.push(item);
      }
    });

    this._datasetQuery.orderBy = [...orderBysArr];
    return this;
  }

  formatExpression(expression: any,
                   {quotes = DISPLAY_QUOTES, ...options} = {}) {
    return expressionFormat(expression, {
      quotes,
      ...options,
      query: this,
    });
  }

  removeOrderBy(index: number) {
    this._datasetQuery.orderBy?.splice(index, 1);
    return this;
  }

  displayInfo4Aggregation(operator: any) {
    return undefined;
  }

  availableMetrics() {
    return [];
  }

  displayMetricInfo(metric: any) {
    return {};
  }

  displayOperatorInfo(operator: any) {
    console.log("StructureQuery --> displayOperatorInfo:", operator);
    return {
      name: operator.name,
    };
  }

  aggregationOperatorGroupColumns(operator: any) {
    return this.groupColumns();
  }

  aggregations(): Aggregation[] {
    let retArr: Aggregation[] = [];
    let idx = 0;
    this._datasetQuery.aggregation?.forEach(item => {
      retArr.push(new Aggregation(item, idx++, this));
    })
    return retArr;
  }

  selectedAggregationOperators(baseOperators: any[], clause: any[] | undefined) {
    return baseOperators;
  }

  addAggregation(operator: any) {
    let arr = this._datasetQuery.aggregation || [];
    arr.push(operator.value);
    this._datasetQuery.aggregation = [...arr];
    return this;
  }

  hasSummarize() {
    return this.aggregations().length > 0 || this.hasBreakouts();
  }

  removeAggregation(aggregation: Aggregation) {
    let arr = this._datasetQuery.aggregation || [];
    arr.splice(aggregation.index(), 1);
    this._datasetQuery.aggregation = [...arr];
    return this;
  }

  getInitialAggregationOperator(stageIndex: number = 0) {
    let arr = this.aggregations() || [];
    if (arr.length > stageIndex) {
      return arr[stageIndex].operator();
    } else {
      return null;
    }
  }

  updateAggregation(idx: number, aggregation: any) {
    let arr = this._datasetQuery.aggregation || [];
    arr[idx] = aggregation.value;
    this._datasetQuery.aggregation = [...arr];
    console.log("StructureQuery-updateAggregation", this);
    return this;
  }

  breakouts(): Breakout[] {
    let ret: Breakout[] = [];
    let arr = this._datasetQuery.breakout || [];
    let idx = 0;
    arr.forEach(item => {
      ret.push(new Breakout(item, idx++, this));
    })

    return ret;
  }

  removeBreakout(index: number) {
    let arr = this._datasetQuery.breakout || [];
    arr.splice(index, 1);
    this._datasetQuery.breakout = [...arr];
    return this;
  }

  addBreakout(field: any) {
    let arr: any[] = this._datasetQuery.breakout || [];
    arr.push(getFieldReference(field, field?.table.alias))
    this._datasetQuery.breakout = [...arr];
    return this;
  }

  updateBreakout(index: number, field: any) {
    let arr: any[] = this._datasetQuery.breakout || [];
    arr[index] = (getFieldReference(field, field?.table.alias));
    this._datasetQuery.breakout = [...arr];
    return this;
  }

  expressions(): ExpressionDimension[] {
    let ret: ExpressionDimension[] = [];
    let expressions = this._datasetQuery.expressions || {};
    for (let key in expressions) {
      ret.push(new ExpressionDimension(key, expressions[key], this._metadata, this));
    }
    return ret;
  }

  removeExpression(name: any) {
    let expressions = this._datasetQuery.expressions || {};
    let ret: any = {};
    for (let key in expressions) {
      if (name != key) {
        ret[key] = expressions[key];
      }
    }
    this._datasetQuery.expressions = {...ret};
    return this;
  }

  getSameNameIdx(newName: any): number {
    let expressions = this._datasetQuery.expressions || {};
    let sameIdx = 0;
    for (let key in expressions) {
      let name = expressions[key]['options'].name;
      if (newName == name) {
        let idx = expressions[key]['options'].sameNameIdx;
        sameIdx = idx + 1;
      }
    }

    return sameIdx;
  }

  getUniName(newName: any, sameIdx: number) {
    return newName + (sameIdx > 0 ? "(" + sameIdx + ")" : "");
  }

  addExpression(newName: any, newExpression: any) {
    let sameIdx: number = this.getSameNameIdx(newName);
    const uniqueName = this.getUniName(newName, sameIdx);

    let expressions = this._datasetQuery.expressions || {};
    expressions[uniqueName] = newExpression;
    expressions[uniqueName]['options'] = {
      name: newName,
      sameNameIdx: sameIdx,
    };
    this._datasetQuery.expressions = {...expressions};

    return this;
  }

  updateExpression(newName: any, newExpression: any, name: any) {
    let expressions = this._datasetQuery.expressions || {};
    let uniqueName = newName;
    let sameIdx: number = 0;
    if (newName != name) {
      sameIdx = this.getSameNameIdx(newName);
      uniqueName = this.getUniName(newName, sameIdx);
    } else {
      sameIdx = expressions[name]['options'].sameNameIdx || 0;
    }

    let ret: any = {};
    for (let key in expressions) {
      if (name == key) {
        ret[uniqueName] = newExpression;
        ret[uniqueName]['options'] = {
          name: newName,
          sameNameIdx: sameIdx,
        };
      } else {
        ret[key] = expressions[key];
      }
    }
    this._datasetQuery.expressions = {...ret};
    return this;
  }
}

export default StructuredQuery;

class NestedStructuredQuery extends StructuredQuery {
  _parent: StructuredQuery;

  constructor(datasetQuery: StructuredQueryType, parent: StructuredQuery) {
    super(datasetQuery);
    this._parent = parent;
  }

  setDatasetQuery(datasetQuery: StructuredQueryType): StructuredQuery {
    return new NestedStructuredQuery(
      datasetQuery,
      this._parent);
    ;
  }

  rootQuery(): StructuredQuery {
    return this.parentQuery().rootQuery();
  }

  parentQuery() {
    return this._parent.setDataQuery(this.dataQuery());
  }
}
