import type { TypeOptions } from "react-toastify";
import { promisify } from "./utils/Promisify";

function tFnDescriptor(message: string, style: TypeOptions) {
  return {
    type: "_T" as const,
    payload: { message, style },
  };
}

export type TArgs = Parameters<typeof tFnDescriptor>;
export type TDescription = ReturnType<typeof tFnDescriptor>;
export type TActionType = TDescription["type"];

async function _t(...args: Parameters<typeof tFnDescriptor>) {
  return promisify(tFnDescriptor)(...args);
}

export default (args:any)=>{
  console.log('_t("' + args + '")');
  return args;
};
