import _ from "lodash";
import type { any } from "prop-types";

/**
 * Generates a universally unique identifier (UUID) with the specified length and radix.
 * @param len The length of the UUID.
 * @param radix The radix for generating the UUID.
 * @returns The generated UUID.   uuid2(16, 16)
 */
export function uuid2(len:any=16, radix:any=16) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}

/**
 * 从给定字符串中截取指定长度的子字符串，并在末尾添加省略号。
 * @param str 要截取的字符串
 * @param len 指定的长度
 * @returns 截取后的子字符串
 */
export function cutString(str: any, len: any) {
    if(_.isEmpty(str)){
        return ""
    }
    if (str.length <= len) {
        return str;
    } else {
        return str.substring(0, len) + "...";
    }
}

/**
 * 下载文件
 *
 * @param url 文件的URL
 * @param filename 文件名
 */
export function downLoadClick(url: any, filename: any) {
    const link = document.createElement('a');
    link.download = filename;
    link.target = "_blank";
    link.href = url;
    link.rel = "noopener noreferrer";
    link.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        link.remove();
    }, 500);
}
//  uuid2(16, 16) // "277571702EE33E11"

/**
 * 数组去重
 *
 * @param array 数组
 * @param label label
 * @param value value
 */
export function removeDuplicates(array:any,label:any,value:any) {
    if(_.isEmpty(array)){
        return [];
    }
    const uniqueArr = array.filter((item:any, index:any, self:any) => {
        return index === self.findIndex((obj:any) => obj[value] === item[value] && obj[label] === item[label]);
    });
    return uniqueArr;
}

export function removeDuplicates2(array:any,label:any,value:any){
    if(_.isEmpty(array)){
        return [];
    }
    const res= new Map()
    return array.filter((a:any)=> !res.has(a[value]) && res.set(a[value],1) && !res.has(a[label]) && res.set(a[label],1))
}

export function removeDuplicates3(array:any,label:any,value:any){
    if(_.isEmpty(array)){
        return [];
    }
    const result = Array.from(new Set(array.map((item:any) => JSON.stringify(item)))).map((item:any) => JSON.parse(item));
    return result;
}

export function getEndOfMonth(dateString:any) {
    // 解析输入的日期字符串
    let date = new Date(dateString);
    // 获取当前日期的年份和月份
    let year = date.getFullYear();
    let month = date.getMonth(); // 注意：月份是从 0 开始的
    // 计算下个月的第一天
    let firstDayNextMonth:any = new Date(year, month + 1, 1);
    // 计算当前月份的最后一天
    let lastDayOfMonth:any = new Date(firstDayNextMonth - 1);
    // 设置时间为 23:59:59
    lastDayOfMonth.setHours(23, 59, 59, 999);
    return lastDayOfMonth;
}

//根据背景颜色返回深浅
export function getContrastYIQ(hex:any) {
    // 从十六进制颜色值中提取红色、绿色和蓝色分量
    let r = parseInt(hex.substr(1, 2), 16);
    let g = parseInt(hex.substr(3, 2), 16);
    let b = parseInt(hex.substr(5, 2), 16);
    // 计算亮度
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    // 如果亮度高于 128，背景颜色较浅，返回黑色；否则，返回白色
    return (yiq >= 128) ? 'black' : 'white';
}

export function strToJson(str:any) {
    if(typeof(str)=="object"){
        return str;
    }
    if(!str){
      return {};
    }
    return JSON.parse(str);
}

export function hexToRgb(hex:any, alpha:any) {
    // 去除 # 符号
    hex = hex.replace('#', '');

    // 如果是缩写形式的颜色值，将其扩展成标准的形式
    if (hex.length === 3) {
        hex = hex.split('').map(function (hex:any) {
            return hex + hex;
        }).join('');
    }

    // 解析十六进制颜色值
    var bigint = parseInt(hex, 16);

    // 获取 R、G、B 组件的值
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    // 如果提供了透明度参数，将其应用于颜色值
    var result = 'rgb(' + r + ', ' + g + ', ' + b + ')';
    if (!isNaN(parseFloat(alpha)) && isFinite(alpha)) {
        result = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }

    return result;
}

// 示例用法
// console.log(hexToRgb('#ff0000')); // 输出: rgb(255, 0, 0)
// console.log(hexToRgb('#00ff00', 0.5)); // 输出: rgba(0, 255, 0, 0.5)

export function getUrlParams() {
    const searchParams = new URLSearchParams(window.location.search);
    const params:any = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }

export function appUrlParams(uname?:string){
  const urlParams = getUrlParams();
  let urlParam = `?appId=${urlParams.appId}&appName=${urlParams.appName}&uname=${uname?uname:(urlParams.uname??'guest')}`;
  return urlParam;
}

export class appTenantClass {
  static tenant:string|null = null;
  static appId:string|null = null;
  static projectId:string|null = null;
  static getTenant(){
    return appTenantClass.tenant;
  }
  static setTenant(s:string){
    appTenantClass.tenant = s;
  }
  static getAppId(){
    return appTenantClass.appId;
  }
  static setAppId(s:string){
    appTenantClass.appId = s;
  }
  static getProjectId(){
    return appTenantClass.projectId;
  }
  static setProjectId(s:string){
    appTenantClass.projectId = s;
  }
  static getIsPreview(){
    return window.location.hostname.indexOf(`-preview`) > 0;
  }
}

///把时间戳转换成年月日格式
export function formatDateTimestamp(timestamp:any) {
    // 创建一个Date对象
    let date = new Date(timestamp);
    // 获取年、月、日
    // 注意月份是从0开始的，所以要+1
    // 使用padStart来确保月份和日期总是两位数
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    // 拼接成"YYYY-MM-DD"格式的字符串
    return `${year}-${month}-${day}`;
  }

  //
  export function isTimestamp(value:any) {
    // 尝试将字符串转换为数字
    const num = Number(value);
    if (!Number.isFinite(num) || num < 0) {
        return false;
    }
    return true;
}
