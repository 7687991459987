import {createLookupByProperty} from "@byk/pages/QueryBuilder/utils/create-lookup-by-property";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {getFilterOperators} from "@byk/pages/QueryBuilder/operatorUtil";
import {FilterOperator} from "@byk/pages/QueryBuilder/lib/metadata/types/deprecated-types";
import {FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import Table from "@byk/pages/QueryBuilder/lib/metadata/Table";

class Field {
  _plainObject: Record<string, unknown>;
  table?: Table;
  tableId?: any;
  query?: StructuredQuery;
  metadata?: any;
  name?: string;
  type?: string;
  id: any;
  fk_target_field_id?: boolean;

  constructor(object = {}, table?: Table) {
    this._plainObject = object;

    for (const property in object) {
      // @ts-ignore
      this[property] = object[property];
    }
    this.table = table;
  }

  getPlainObject() {
    return this._plainObject;
  }

  filterOperator(operatorName: string): FilterOperator {
    return this.filterOperatorsLookup()[operatorName];
  }

  filterOperatorsLookup(): any {
    return createLookupByProperty(this.filterOperators(), "name");
  }

  filterOperators(selected?: any) {
    return getFilterOperators(this, this.table, selected);
  }

  isBoolean() {
    return false;
  }

  isSummable() {
    return false;
  }

  displayName(includeTable?: boolean) {
    let displayName = "";

    if (includeTable && this.table?.name) {
      displayName +=
        this.table.name + " → ";
    }

    displayName += this.name;

    return displayName;
  }

  targetObjectName() {
    return "";
  }

  isDate() {
    return this.type == 'date';
  }

  isFK() {
    return false;
  }

  isPK() {
    return false;
  }

  reference() {
    return ["field", this.id, null];
  }

  dimension(): FieldDimension {
    const ref = this.reference();
    const fieldDimension = new FieldDimension(
      ref[1],
      ref[2],
      this.metadata,
      this.query,
      {
        _fieldInstance: this,
      },
    );

    return fieldDimension;
  }

  icon() {
    return "label";
  }
}

export default Field;
