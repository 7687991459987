import API from "api/Api";
import type { AxiosPromise } from "axios";
import type { JSCollection } from "entities/JSCollection";
import type { ApiResponse } from "./ApiResponses";
import type { Variable, JSAction } from "entities/JSCollection";
import type { PluginType } from "entities/Action";
import _ from 'lodash';
import {apiPath} from "@byk/routes";
import {getTenant} from "../utils/localStorage";
export type JSCollectionCreateUpdateResponse = ApiResponse & {
  id: string;
};
type Uniq = <T>(
  arr: T[],
  iteratee: (o: T) => string
) => T[];
const uniq: Uniq = _.uniq;

export interface MoveJSCollectionRequest {
  collectionId: string;
  destinationPageId: string;
  name: string;
}
export interface UpdateJSObjectNameRequest {
  pageId?: string;
  id?: string;
  actionCollectionId?: string;
  layoutId: string;
  newName: string;
  name?: string;
  oldName: string;
}

export interface CreateJSCollectionRequest {
  name: string;
  pageId: string;
  workspaceId: string;
  pluginId: string;
  body: string;
  variables: Array<Variable>;
  actions: Array<Partial<JSAction>>;
  applicationId: string;
  pluginType: PluginType;
}

export type SetFunctionPropertyPayload = {
  action: JSAction;
  propertyName: string;
  value: any;
};
export interface RefactorAction {
  pageId: string;
  actionId: string;
  newName: string;
  oldName: string;
  collectionName: string;
}
export interface RefactorActionRequest extends RefactorAction {
  layoutId: string;
}

export interface UpdateCollectionActionNameRequest {
  refactorAction: RefactorActionRequest;
  actionCollection: JSCollection;
}
class JSActionAPI extends API {
  static url = "v1/collections/actions";

  static fetchJSCollections(
    applicationId: string,
  ): AxiosPromise<ApiResponse<JSCollection[]>> {
    return API.get(JSActionAPI.url, { applicationId });
  }

  static createJSCollection(
    jsConfig: CreateJSCollectionRequest,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    return API.post(JSActionAPI.url, jsConfig);
  }

  static copyJSCollection(
    jsConfig: Partial<JSCollection>,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    return API.post(JSActionAPI.url, jsConfig);
  }

  static updateJSCollection(
    jsConfig: JSCollection,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    const jsAction = Object.assign({}, jsConfig);
    return API.put(`${JSActionAPI.url}/648180d60c9cdb68db9c6446`, jsAction);
  }

  static deleteJSCollection(id: string) {
    return API.delete(`${JSActionAPI.url}/${id}`);
  }

  static moveJSCollection(moveRequest: MoveJSCollectionRequest) {
    return API.put(JSActionAPI.url + "/move", moveRequest);
  }

  static fetchJSCollectionsByPageId(
    pageId: string,
  ): AxiosPromise<ApiResponse<JSCollection[]>> {
    return API.get(JSActionAPI.url, { pageId });
  }

  static fetchJSCollectionsForViewMode(
    applicationId: string,
  ): AxiosPromise<ApiResponse<JSCollection[]>> {
    return API.get(`${JSActionAPI.url}/view`, { applicationId });
  }

  static updateJSCollectionOrActionName(
    updateJSObjectNameRequest: UpdateJSObjectNameRequest,
  ) {
    return API.put(JSActionAPI.url + "/refactor", updateJSObjectNameRequest);
  }

  static updateJSCollectionActionRefactor(
    updateJSCollectionActionName: UpdateCollectionActionNameRequest,
  ) {
    return API.put(
      JSActionAPI.url + "/refactorAction",
      updateJSCollectionActionName,
    );
  }
  //byk
  //创建js
  static createJSCollectionByk(
    jsConfig: CreateJSCollectionRequest,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    return API.post(`/byk/platform/subapp/Collections`, jsConfig);
  }
  //查询
  static fetchJSCollectionsByk(
    params: {
      pageId?: string,
      appId?: string
    },
  ): AxiosPromise<ApiResponse<JSCollection[]>> {
    return API.get(`${apiPath}/${getTenant()}/subapp/Collections/list`, params);
  }
  //删除js
  static deleteJSCollectionByk(id: string) {
    return API.deleteByk(`/byk/platform/subapp/Collections`, [id]);
  }
  //修改js命名
  static updateJSCollectionOrActionNameByk(
    updateJSObjectNameRequest: UpdateJSObjectNameRequest,
  ) {
    return API.put(`/byk/platform/subapp/Collections`, updateJSObjectNameRequest);
  }
  //修改js body
  static updateJSCollectionByk(
    jsConfig: JSCollection,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    const { actions } = jsConfig;
    const jsAction = {
      ...jsConfig,
      actions: uniq(actions, o=>o.name)
    };
    return API.put(`/byk/platform/subapp/Collections`, jsAction);
  }
  //修改actions
  static updateJSActionsByk(
    jsConfig: JSCollection,
  ): AxiosPromise<JSCollectionCreateUpdateResponse> {
    const jsAction = Object.assign({}, jsConfig);
    return API.put(`/byk/platform/rest/Actions`, jsAction);
  }
}

export default JSActionAPI;
