import MBQLArrayClause from "@byk/pages/QueryBuilder/lib/queries/structured/MBQLClause";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {AGGREGATION_OPERATORS} from "@byk/pages/QueryBuilder/constants";

export default class Aggregation extends MBQLArrayClause {
  private _operator: any;

  constructor(mbql: any[],
              index?: number | null | undefined,
              query?: StructuredQuery) {
    super(mbql, index, query);
    Object.assign(this, mbql);

    this._operator = AGGREGATION_OPERATORS.filter(value => mbql[0] == value.short)[0];
  }

  longDisplayName() {
    return this._operator.columnName;
  }

  operator() {
    return this._operator;
  }

}
