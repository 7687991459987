// @ts-ignore
import _ from "underscore";
import {
  BOOLEAN,
  FIELD_FILTER_OPERATORS,
  FILTER_OPERATORS_BY_TYPE_ORDERED,
  FOREIGN_KEY,
  LOCATION,
  MORE_VERBOSE_NAMES,
  NUMBER,
  PRIMARY_KEY,
  STRING,
  STRING_LIKE,
  TEMPORAL,
  TYPE_HIERARCHIES,
  UNKNOWN,
} from "@byk/pages/QueryBuilder/constants";


export function isFieldType(type: string, field: any) {
  if (!field) {
    return false;
  }

  const typeDefinition = TYPE_HIERARCHIES[type];
  // check to see if it belongs to any of the field types:
  const props = field.effective_type
    ? ["effective", "semantic"]
    : ["base", "semantic"];
  for (const prop of props) {
    const allowedTypes = typeDefinition[prop];
    if (!allowedTypes) {
      continue;
    }

    const fieldType = field[prop + "_type"];
    for (const allowedType of allowedTypes) {
      if (fieldType == allowedType) {
        return true;
      }
    }
  }

  // recursively check to see if it's NOT another field type:
  for (const excludedType of typeDefinition.exclude || []) {
    if (isFieldType(excludedType, field)) {
      return false;
    }
  }

  // recursively check to see if it's another field type:
  for (const includedType of typeDefinition.include || []) {
    if (isFieldType(includedType, field)) {
      return true;
    }
  }
  return false;
}

export function getFieldType(field: any) {
  // try more specific types first, then more generic types
  for (const type of [
    TEMPORAL,
    LOCATION,
    FOREIGN_KEY,
    PRIMARY_KEY,
    BOOLEAN,
    STRING,
    STRING_LIKE,
    NUMBER,
  ]) {
    if (isFieldType(type, field)) {
      return type;
    }
  }
}

export function getOperatorByTypeAndName(type: any, name: any) {
  const typedNamedOperator = _.findWhere(
    FILTER_OPERATORS_BY_TYPE_ORDERED[type],
    {
      name,
    },
  );
  const namedOperator = FIELD_FILTER_OPERATORS[name];

  return (
    typedNamedOperator && {
      ...typedNamedOperator,
      ...namedOperator,
      numFields: namedOperator.validArgumentsFilters.length,
    }
  );
}

export function getFilterOperators(field: any, table: any, selected: any) {
  const fieldType = getFieldType(field) || UNKNOWN;
  let type = fieldType;
  if (type === PRIMARY_KEY || type === FOREIGN_KEY) {
    if (isFieldType(STRING, field)) {
      type = STRING;
    } else if (isFieldType(STRING_LIKE, field)) {
      type = STRING_LIKE;
    }
  }

  return FILTER_OPERATORS_BY_TYPE_ORDERED[type]
    .map((operatorForType: any) => {
      const operator = FIELD_FILTER_OPERATORS[operatorForType.name];
      const verboseNameLower = operatorForType.verboseName.toLowerCase();
      return {
        ...operator,
        ...operatorForType,
        moreVerboseName:
          MORE_VERBOSE_NAMES[verboseNameLower] || verboseNameLower,
        fields: operator.validArgumentsFilters.map((validArgumentsFilter: any) =>
          validArgumentsFilter(field, table),
        ),
      };
    })
    .filter((operator: any) => {
      if (selected === undefined) {
        return true;
      }
      if (type === "STRING" || type === "STRING_LIKE") {
        if (selected === "is-null") {
          return operator["name"] !== "not-null";
        } else if (selected === "not-null") {
          return operator["name"] !== "is-null";
        } else {
          return (
            operator["name"] !== "not-null" && operator["name"] !== "is-null"
          );
        }
      }
      return true;
    });
}
