import _ from "lodash";

export const NUMBER = "NUMBER";
export const INTEGER = "INTEGER";
export const STRING = "STRING";
export const STRING_LIKE = "STRING_LIKE";
export const BOOLEAN = "BOOLEAN";
export const TEMPORAL = "TEMPORAL";
export const LOCATION = "LOCATION";
export const COORDINATE = "COORDINATE";
export const FOREIGN_KEY = "FOREIGN_KEY";
export const PRIMARY_KEY = "PRIMARY_KEY";

// other types used for various purposes
const ENTITY = "ENTITY";
export const SUMMABLE = "SUMMABLE";
export const SCOPE = "SCOPE";
export const CATEGORY = "CATEGORY";
const DIMENSION = "DIMENSION";

export const UNKNOWN = "UNKNOWN";

export const TYPE_HIERARCHIES: any = {
  TEMPORAL: {
    base: ['type/Temporal'],
    effective: ['type/Temporal'],
    semantic: ['type/Temporal'],
  },
  NUMBER: {
    base: ['type/Number'],
    effective: ['type/Number'],
    semantic: ['type/Number'],
  },
  INTEGER: {
    base: ['type/Integer'],
    effective: ['type/Integer'],
  },
  STRING: {
    base: ['type/Text'],
    effective: ['type/Text'],
    semantic: ['type/Text', 'type/Category'],
  },
  STRING_LIKE: {
    base: ['type/TextLike'],
    effective: ['type/TextLike'],
  },
  BOOLEAN: {
    base: ['type/Boolean'],
    effective: ['type/Boolean'],
  },
  COORDINATE: {
    semantic: ['type/Coordinate'],
  },
  LOCATION: {
    semantic: ['type/Address'],
  },
  ENTITY: {
    semantic: ['type/FK', 'type/PK', 'type/Name'],
  },
  FOREIGN_KEY: {
    semantic: ['type/FK'],
  },
  PRIMARY_KEY: {
    semantic: ['type/PK'],
  },
  SUMMABLE: {
    include: [NUMBER],
    exclude: [ENTITY, LOCATION, TEMPORAL],
  },
  SCOPE: {
    include: [NUMBER, TEMPORAL, CATEGORY, ENTITY, STRING],
    exclude: [LOCATION],
  },
  CATEGORY: {
    base: ['type/Boolean'],
    effective: ['type/Boolean'],
    semantic: ['type/Category'],
    include: [LOCATION],
  },
  // NOTE: this is defunct right now.  see definition of isDimension below.
  DIMENSION: {
    include: [TEMPORAL, CATEGORY, ENTITY],
  },
};

function freeformArgument(field: any) {
  return {
    type: "text",
  };
}

function equivalentArgument(field: any) {
  return {
    type: "text",
  };
}


function comparableArgument(field: any) {
  return {
    type: "text",
  };
}

const CASE_SENSITIVE_OPTION = {
  "case-sensitive": {
    defaultValue: true,
  },
};

// each of these has an implicit field argument, followed by 0 or more additional arguments
export const FIELD_FILTER_OPERATORS: any = {
  "=": {
    validArgumentsFilters: [equivalentArgument],
    multi: true,
  },
  "!=": {
    validArgumentsFilters: [equivalentArgument],
    multi: true,
  },
  "is-empty": {
    validArgumentsFilters: [],
  },
  "not-empty": {
    validArgumentsFilters: [],
  },
  "is-null": {
    validArgumentsFilters: [],
  },
  "not-null": {
    validArgumentsFilters: [],
  },
  "<": {
    validArgumentsFilters: [comparableArgument],
  },
  "<=": {
    validArgumentsFilters: [comparableArgument],
  },
  ">": {
    validArgumentsFilters: [comparableArgument],
  },
  ">=": {
    validArgumentsFilters: [comparableArgument],
  },
  between: {
    validArgumentsFilters: [comparableArgument, comparableArgument],
  },
  "starts-with": {
    validArgumentsFilters: [freeformArgument],
    options: CASE_SENSITIVE_OPTION,
    optionsDefaults: {"case-sensitive": false},
  },
  "ends-with": {
    validArgumentsFilters: [freeformArgument],
    options: CASE_SENSITIVE_OPTION,
    optionsDefaults: {"case-sensitive": false},
  },
  contains: {
    validArgumentsFilters: [freeformArgument],
    options: CASE_SENSITIVE_OPTION,
    optionsDefaults: {"case-sensitive": false},
  },
  "does-not-contain": {
    validArgumentsFilters: [freeformArgument],
    options: CASE_SENSITIVE_OPTION,
    optionsDefaults: {"case-sensitive": false},
  },
};

const DEFAULT_FILTER_OPERATORS = [
  {name: "=", verboseName: "等于"},
  {name: "!=", verboseName: "不等于"},
  {name: "is-null", verboseName: "为空"},
  {name: "not-null", verboseName: "不为空"},
];

const KEY_FILTER_OPERATORS = [
  {name: "=", verboseName: "等于"},
  {name: "!=", verboseName: "不等于"},
  {name: ">", verboseName: "大于"},
  {name: "<", verboseName: "小于"},
  {name: "between", verboseName: "在…之间"},
  {name: ">=", verboseName: "大于或等于"},
  {name: "<=", verboseName: "小于或等于"},
  {name: "is-null", verboseName: "为空"},
  {name: "not-null", verboseName: "不为空"},
];

export const FILTER_OPERATORS_BY_TYPE_ORDERED: any = {
  [NUMBER]: [
    {name: "=", verboseName: "等于"},
    {name: "!=", verboseName: "不等于"},
    {name: ">", verboseName: "大于"},
    {name: "<", verboseName: "小于"},
    {name: "between", verboseName: "在…之间"},
    {name: ">=", verboseName: "大于或等于"},
    {name: "<=", verboseName: "小于或等于"},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不为空"},
  ],
  [STRING]: [
    {name: "=", verboseName: "等于"},
    {name: "!=", verboseName: "不等于"},
    {name: "contains", verboseName: "包含"},
    {name: "does-not-contain", verboseName: "不包含"},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不是空的"},
    {name: "is-empty", verboseName: "为空"},
    {name: "not-empty", verboseName: "不为空"},
    {name: "starts-with", verboseName: "以……开始"},
    {name: "ends-with", verboseName: "以……结束"},
  ],
  [STRING_LIKE]: [
    {name: "=", verboseName: "等于"},
    {name: "!=", verboseName: "不等于"},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不是空的"},
    {name: "is-empty", verboseName: "为空"},
    {name: "not-empty", verboseName: "不为空"},
  ],
  [TEMPORAL]: [
    {name: "!=", verboseName: "不包括"},
    {name: "=", verboseName: "等于"},
    {name: "<", verboseName: "之前"},
    {name: ">", verboseName: "之后"},
    {name: "between", verboseName: "在…之间"},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不是空的"},
  ],
  [LOCATION]: [
    {name: "=", verboseName: "等于"},
    {name: "!=", verboseName: "不等于"},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不为空"},
    {name: "contains", verboseName: "包含"},
    {name: "does-not-contain", verboseName: "不包含"},
    {name: "starts-with", verboseName: "以……开始"},
    {name: "ends-with", verboseName: "以……结束"},
  ],
  [COORDINATE]: [
    {name: "=", verboseName: "等于"},
    {name: "!=", verboseName: "不等于"},
    {name: "inside", verboseName: "内部"},
    {name: ">", verboseName: "大于"},
    {name: "<", verboseName: "小于"},
    {name: "between", verboseName: "在…之间"},
    {name: ">=", verboseName: "大于或等于"},
    {name: "<=", verboseName: "小于或等于"},
  ],
  [BOOLEAN]: [
    {name: "=", verboseName: "等于", multi: false},
    {name: "is-null", verboseName: "为空"},
    {name: "not-null", verboseName: "不为空"},
  ],
  [FOREIGN_KEY]: KEY_FILTER_OPERATORS,
  [PRIMARY_KEY]: KEY_FILTER_OPERATORS,
  [UNKNOWN]: DEFAULT_FILTER_OPERATORS,
};

export const MORE_VERBOSE_NAMES: any = {
  "equal to": "等于",
  "not equal to": "不等于",
  before: "之前",
  after: "之后",
  "not empty": "不是空的",
  "not null": "不是空的",
  "less than": "小于",
  "greater than": "大于",
  "less than or equal to": "小于等于",
  "greater than or equal to": "大于或等于",
};

// Breakouts and Aggregation options
function allFields(fields: any) {
  return fields;
}

function summableFields(fields: any) {
  return _.filter(fields, (field: any) => {
    return true;
  });
}

function scopeFields(fields: any) {
  return _.filter(fields, (field: any) => {
    return true;
  });
}

export const AGGREGATION_OPERATORS = [
  {
    short: "count",
    name: "行数",
    columnName: "行数",
    description: "答案中的总行数。",
    validFieldsFilters: [],
    requiresField: false,
    requiredDriverFeature: "basic-aggregations",
    value: ["count"],
  },
  {
    short: "sum",
    name: "求和",
    columnName: "总和",
    description: "一个字段所有数值的总和。",
    validFieldsFilters: [summableFields],
    requiresField: true,
    requiredDriverFeature: "basic-aggregations",
    value: ["sum"],
  },
  {
    short: "avg",
    name: "平均值",
    columnName: "平均值",
    description: "一个字段所有数值的平均值。",
    validFieldsFilters: [summableFields],
    requiresField: true,
    requiredDriverFeature: "basic-aggregations",
    value: ["avg"],
  },
  {
    short: "distinct",
    name: "去重计算",
    columnName: "唯一值",
    description: "一个字段所有互不重复的值的总数",
    validFieldsFilters: [allFields],
    requiresField: true,
    requiredDriverFeature: "basic-aggregations",
    value: ["distinct"],
  },
  {
    short: "min",
    name: "最小值...",
    columnName: "最小值",
    description: "一个字段的最小值",
    validFieldsFilters: [scopeFields],
    requiresField: true,
    requiredDriverFeature: "basic-aggregations",
    value: ["min"],
  },
  {
    short: "max",
    name: "最大值...",
    columnName: "最大值",
    description: "一个字段的最大值",
    validFieldsFilters: [scopeFields],
    requiresField: true,
    requiredDriverFeature: "basic-aggregations",
    value: ["max"],
  },
];
