import { all, takeEvery, call, put, select } from "redux-saga/effects";
//import type { ReduxAction } from "@appsmith/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "@appsmith/constants/ReduxActionConstants";
import type { DefaultPlugin, PluginFormPayload } from "api/PluginApi";
import PluginsApi from "api/PluginApi";
import { validateResponse } from "sagas/ErrorSagas";
//import { getCurrentWorkspaceId } from "@appsmith/selectors/workspaceSelectors";
import {
  getActions,
  getDatasources,
  getPlugin,
  getPluginForm,
  getPlugins,
} from "selectors/entitiesSelector";
import type { Datasource } from "entities/Datasource";
import type { Plugin } from "api/PluginApi";
import {
  fetchPluginFormConfigsSuccess,
  fetchPluginFormConfigSuccess,
  fetchPluginFormConfigError,
} from "actions/pluginActions";
import {
  defaultActionDependenciesConfig,
  defaultActionEditorConfigs,
  defaultActionSettings,
  defaultDatasourceFormButtonConfig,
} from "constants/AppsmithActionConstants/ActionConstants";
import type { ApiResponse } from "api/ApiResponses";
import PluginApi from "api/PluginApi";
import log from "loglevel";
import { getGraphQLPlugin, PluginType } from "entities/Action";
import type {
  FormEditorConfigs,
  FormSettingsConfigs,
  FormDependencyConfigs,
  FormDatasourceButtonConfigs,
} from "utils/DynamicBindingUtils";
import type { ActionDataState } from "reducers/entityReducers/actionsReducer";

function* fetchPluginsSaga(
  //action: ReduxAction<{ workspaceId?: string } | undefined>,
) {
  try {
    // let workspaceId: string = yield select(getCurrentWorkspaceId);
    // if (action.payload?.workspaceId) workspaceId = action.payload?.workspaceId;
    //
    // if (!workspaceId) {
    //   throw Error("Workspace id does not exist");
    // }
    // const pluginsResponse: ApiResponse<Plugin[]> = yield call(
    //   PluginsApi.fetchPlugins,
    //   workspaceId,
    // );
    // const isValid: boolean = yield validateResponse(pluginsResponse);
    // if (isValid) {
      yield put({
        type: ReduxActionTypes.FETCH_PLUGINS_SUCCESS,
        payload: [{
          "id": "642398e3dce1f56873f3c79e",
          "userPermissions": [],
          "name": "REST API",
          "type": "API",
          "packageName": "restapi-plugin",
          "iconLocation": "https://assets.appsmith.com/RestAPI.png",
          "uiComponent": "ApiEditorForm",
          "datasourceComponent": "RestAPIDatasourceForm",
          "allowUserDatasources": true,
          "isRemotePlugin": false,
          "templates": {},
          "remotePlugin": false,
          "new": false
        },{
          "id": "642398e9dce1f56873f3c7af",
          "userPermissions": [],
          "name": "JS Functions",
          "type": "JS",
          "packageName": "js-plugin",
          "iconLocation": "https://assets.appsmith.com/JSFile.svg",
          "documentationLink": "https://docs.appsmith.com/v/v1.2.1/js-reference/using-js",
          "responseType": "JSON",
          "uiComponent": "JsEditorForm",
          "allowUserDatasources": true,
          "isRemotePlugin": false,
          "templates": {},
          "remotePlugin": false,
          "new": false
        }]//pluginsResponse.data,
      });
    //}
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_PLUGINS_ERROR,
      payload: { error },
    });
  }
}
const handlePluginForm = ()=>{
  return {
    "form": [
      {
        "sectionName": "General",
        "children": [
          {
            "label": "URL",
            "configProperty": "datasourceConfiguration.url",
            "controlType": "INPUT_TEXT",
            "isRequired": true,
            "placeholderText": "https://example.com"
          },
          {
            "label": "Headers",
            "configProperty": "datasourceConfiguration.headers",
            "controlType": "KEYVALUE_ARRAY"
          },
          {
            "label": "Query Params",
            "configProperty": "datasourceConfiguration.queryParameters",
            "controlType": "KEYVALUE_ARRAY"
          },
          {
            "label": "Send Authentication Information Key (Do not edit)",
            "configProperty": "datasourceConfiguration.properties[0].key",
            "controlType": "INPUT_TEXT",
            "hidden": true,
            "initialValue": "isSendSessionEnabled"
          },
          {
            "label": "Send Appsmith signature header (X-APPSMITH-SIGNATURE)",
            "configProperty": "datasourceConfiguration.properties[0].value",
            "controlType": "DROP_DOWN",
            "isRequired": true,
            "initialValue": "N",
            "options": [
              {
                "label": "Yes",
                "value": "Y"
              },
              {
                "label": "No",
                "value": "N"
              }
            ]
          },
          {
            "label": "Session Details Signature Key Key (Do not edit)",
            "configProperty": "datasourceConfiguration.properties[1].key",
            "controlType": "INPUT_TEXT",
            "hidden": true,
            "initialValue": "sessionSignatureKey"
          },
          {
            "label": "Session Details Signature Key",
            "configProperty": "datasourceConfiguration.properties[1].value",
            "controlType": "INPUT_TEXT",
            "hidden": {
              "path": "datasourceConfiguration.properties[0].value",
              "comparison": "EQUALS",
              "value": "N"
            }
          },
          {
            "label": "Authentication Type",
            "configProperty": "datasourceConfiguration.authentication.authenticationType",
            "controlType": "DROP_DOWN",
            "options": [
              {
                "label": "None",
                "value": "dbAuth"
              },
              {
                "label": "Basic",
                "value": "basic"
              },
              {
                "label": "OAuth 2.0",
                "value": "oAuth2"
              },
              {
                "label": "API Key",
                "value": "apiKey"
              },
              {
                "label": "Bearer Token",
                "value": "bearerToken"
              }
            ]
          },
          {
            "label": "Grant Type",
            "configProperty": "datasourceConfiguration.authentication.grantType",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": true
          },
          {
            "label": "Access Token URL",
            "configProperty": "datasourceConfiguration.authentication.accessTokenUrl",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Client Id",
            "configProperty": "datasourceConfiguration.authentication.clientId",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Client Secret",
            "configProperty": "datasourceConfiguration.authentication.clientSecret",
            "dataType": "PASSWORD",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Scope(s)",
            "configProperty": "datasourceConfiguration.authentication.scopeString",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Header Prefix",
            "configProperty": "datasourceConfiguration.authentication.headerPrefix",
            "controlType": "INPUT_TEXT",
            "placeholderText": "Bearer (default)",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Add token to",
            "configProperty": "datasourceConfiguration.authentication.isTokenHeader",
            "controlType": "DROP_DOWN",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            },
            "options": [
              {
                "label": "Header",
                "value": true
              },
              {
                "label": "Query parameters",
                "value": false
              }
            ]
          },
          {
            "label": "Audience(s)",
            "configProperty": "datasourceConfiguration.authentication.audience",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Resource(s)",
            "configProperty": "datasourceConfiguration.authentication.resource",
            "controlType": "INPUT_TEXT",
            "isRequired": false,
            "hidden": {
              "path": "datasourceConfiguration.authentication.authenticationType",
              "comparison": "NOT_EQUALS",
              "value": "oAuth2"
            }
          },
          {
            "label": "Send scope with refresh token",
            "configProperty": "datasourceConfiguration.authentication.sendScopeWithRefreshToken",
            "controlType": "DROP_DOWN",
            "isRequired": true,
            "initialValue": false,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          {
            "label": "Send client credentials with (on refresh token)",
            "configProperty": "datasourceConfiguration.authentication.refreshTokenClientCredentialsLocation",
            "controlType": "DROP_DOWN",
            "isRequired": true,
            "initialValue": "BODY",
            "options": [
              {
                "label": "Body",
                "value": "BODY"
              },
              {
                "label": "Header",
                "value": "HEADER"
              }
            ]
          }
        ]
      }
    ]
  };
};
function* fetchPluginFormConfigsSaga() {
  try {
    const datasources: Datasource[] = yield select(getDatasources);
    const plugins: Plugin[] = yield select(getPlugins);
    // Add plugins of all the datasources of their workspace
    const pluginIdFormsToFetch = new Set(
      datasources.map((datasource) => datasource.pluginId),
    );
    // Add the api plugin id by default as API, JS, Graphql can exists without datasource
    const apiPlugin = plugins.find((plugin) => plugin.type === PluginType.API);
    const jsPlugin = plugins.find((plugin) => plugin.type === PluginType.JS);
    const graphqlPlugin = getGraphQLPlugin(plugins);
    if (apiPlugin) {
      //pluginIdFormsToFetch.add(apiPlugin.id);
    }

    if (graphqlPlugin) {
      pluginIdFormsToFetch.add(graphqlPlugin.id);
    }

    const actions: ActionDataState = yield select(getActions);
    const actionPluginIds = actions.map((action) => action.config.pluginId);
    for (const pluginId of actionPluginIds) {
      pluginIdFormsToFetch.add(pluginId);
    }
    const pluginFormData:any[] = [];
    // const pluginFormResponses: ApiResponse<PluginFormPayload>[] = yield all(
    //   [...pluginIdFormsToFetch].map((id) =>
    //     call(PluginsApi.fetchFormConfig, id),
    //   ),
    // );
    // const pluginFormResponses: ApiResponse<PluginFormPayload>[] = yield handlePluginForm();
    // for (const response of pluginFormResponses) {
    //   yield validateResponse(response);
    //   pluginFormData.push(response.data);
    // }
    pluginFormData.push(handlePluginForm());
    if (jsPlugin) {
      pluginIdFormsToFetch.add(jsPlugin.id);
    }
    const formConfigs: Record<string, any[]> = {};
    const editorConfigs: FormEditorConfigs = {};
    const settingConfigs: FormSettingsConfigs = {};
    const dependencies: FormDependencyConfigs = {};
    const datasourceFormButtonConfigs: FormDatasourceButtonConfigs = {};

    Array.from(pluginIdFormsToFetch).forEach((pluginId, index) => {
      const plugin = plugins.find((plugin) => plugin.id == pluginId);
      if (plugin && plugin.type === PluginType.JS) {
        settingConfigs[pluginId] = defaultActionSettings[plugin.type];
        editorConfigs[pluginId] = defaultActionEditorConfigs[plugin.type];
        formConfigs[pluginId] = [];
        dependencies[pluginId] = defaultActionDependenciesConfig[plugin.type];
      } else {
        // Datasource form always use server's copy
        formConfigs[pluginId] = pluginFormData[index].form;
        // Action editor form if not available use default
        if (plugin && !pluginFormData[index].editor) {
          editorConfigs[pluginId] = defaultActionEditorConfigs[plugin.type];
        } else {
          editorConfigs[pluginId] = pluginFormData[index].editor;
        }
        // Action settings form if not available use default
        if (plugin && !pluginFormData[index].setting) {
          settingConfigs[pluginId] = defaultActionSettings[plugin.type];
        } else {
          settingConfigs[pluginId] = pluginFormData[index].setting;
        }
        // Action dependencies config if not available use default
        if (plugin && !pluginFormData[index].dependencies) {
          dependencies[pluginId] = defaultActionDependenciesConfig[plugin.type];
        } else {
          dependencies[pluginId] = pluginFormData[index].dependencies;
        }
        // Datasource form buttons config if not available use default
        if (plugin && !pluginFormData[index].formButton) {
          datasourceFormButtonConfigs[pluginId] =
            defaultDatasourceFormButtonConfig[plugin.type];
        } else {
          datasourceFormButtonConfigs[pluginId] =
            pluginFormData[index].formButton;
        }
      }
    });
    yield put(
      fetchPluginFormConfigsSuccess({
        formConfigs,
        editorConfigs,
        settingConfigs,
        dependencies,
        datasourceFormButtonConfigs,
      }),
    );
  } catch (error) {
    log.error(error);
    yield put({
      type: ReduxActionErrorTypes.FETCH_PLUGIN_FORM_CONFIGS_ERROR,
      payload: { error },
    });
  }
}

export function* checkAndGetPluginFormConfigsSaga(pluginId: string) {
  try {
    const plugin: Plugin = yield select(getPlugin, pluginId);
    const formConfig: Record<string, unknown> = yield select(
      getPluginForm,
      pluginId,
    );
    if (!formConfig) {
      const formConfigResponse: ApiResponse<PluginFormPayload> = yield PluginApi.fetchFormConfig(pluginId);
      yield validateResponse(formConfigResponse);
      if (!formConfigResponse.data.setting) {
        formConfigResponse.data.setting = defaultActionSettings[plugin.type];
      }
      if (!formConfigResponse.data.editor) {
        formConfigResponse.data.editor =
          defaultActionEditorConfigs[plugin.type];
      }
      if (!formConfigResponse.data.dependencies) {
        formConfigResponse.data.dependencies =
          defaultActionDependenciesConfig[plugin.type];
      }
      if (!formConfigResponse.data.formButton) {
        formConfigResponse.data.formButton =
          defaultDatasourceFormButtonConfig[plugin.type];
      }
      yield put(
        fetchPluginFormConfigSuccess({
          id: pluginId,
          ...formConfigResponse.data,
        }),
      );
    }
  } catch (e) {
    log.error("Failed to get plugin form");
    yield put(
      fetchPluginFormConfigError({
        id: pluginId,
      }),
    );
  }
}

type GetPluginFormConfigParams = { id: string; type: string };

function* getPluginFormConfig({ id }: GetPluginFormConfigParams) {
  yield call(checkAndGetPluginFormConfigsSaga, id);
}

function* getDefaultPluginsSaga() {
  try {
    const response: ApiResponse<DefaultPlugin> = yield call(
      PluginsApi.fetchDefaultPlugins,
    );
    const isValid: boolean = yield validateResponse(response);
    if (isValid) {
      yield put({
        type: ReduxActionTypes.GET_DEFAULT_PLUGINS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GET_DEFAULT_PLUGINS_ERROR,
      payload: {
        error,
      },
    });
  }
}

function* root() {
  yield all([
    takeEvery(ReduxActionTypes.FETCH_PLUGINS_REQUEST, fetchPluginsSaga),
    takeEvery(
      ReduxActionTypes.FETCH_PLUGIN_FORM_CONFIGS_REQUEST,
      fetchPluginFormConfigsSaga,
    ),
    takeEvery(
      ReduxActionTypes.GET_PLUGIN_FORM_CONFIG_INIT,
      getPluginFormConfig,
    ),
    takeEvery(
      ReduxActionTypes.GET_DEFAULT_PLUGINS_REQUEST,
      getDefaultPluginsSaga,
    ),
  ]);
}

export default root;
