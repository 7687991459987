import { Checkbox, Col, Form, Input, Radio, Row, Select } from "antd";
import SketchPicker from "./SketchPicker";
import React, { useEffect,  } from "react";
import {borderOpt, fontWeightOpt} from './StyleOption';
import styled from "styled-components";
export const XTitle = styled.div`
  font-weight: bold;
`;

export const XCheckbox = (props:any)=>{
  const {value, onChange} = props;
  function onCheckboxChange(ev:any){
    onChange(ev.target.checked);
  }
  return (
    <Checkbox checked={value} onChange={onCheckboxChange} />
  )
}

const TopStyles = (props:any)=>{
  const {layout, defaultValues, onValuesChangeTop} = props;
  const [form] = Form.useForm();
  useEffect(()=>{
    form.setFieldsValue({
      top: {
        height: "60",
        fontSize: '14',
        selectFontSize: '14',
        align: 'left',
        position: 'static',
        float: false,
        ...defaultValues.top,
      },
    })
  }, [defaultValues]);
  function onValuesChange(values:any){
    //console.log(values);
    onValuesChangeTop(values);
  }
  return (
    <Form form={form} {...layout} name="top" onValuesChange={onValuesChange}>
      <Form.Item name={['top', 'align']} label="对齐" labelAlign="left" style={{marginBottom: 0}}>
        <Radio.Group>
          <Radio value={'left'}>左</Radio>
          <Radio value={'center'}>中</Radio>
          <Radio value={'right'}>右</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['top', 'position']} label="固定" labelAlign="left" style={{marginBottom: 0}}>
        <Radio.Group>
          <Radio value={'fixed'}>是</Radio>
          <Radio value={'static'}>否</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['top', 'float']} label="漂浮" labelAlign="left" style={{marginBottom: 0}}>
        <Radio.Group>
          <Radio value={true}>是</Radio>
          <Radio value={false}>否</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['top', 'height']} label="导航高度" labelAlign="left" style={{marginBottom: 0}}>
        <Input suffix="px" />
      </Form.Item>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['top', 'isBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['top', 'backgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>背景色</Col>
      </Row>
      <Form.Item name={['top', 'width']} label="菜单宽度" labelAlign={'left'} style={{margin: '10px 0 0 0'}}>
        <Input suffix="px" />
      </Form.Item>
      <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['top', 'fontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['top', 'fontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['top', 'fontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{margin: '10px 0',borderTop: '1px solid #ccc'}} />
      <XTitle>选中项</XTitle>
      <Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['top', 'selectIsBgColor']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['top', 'selectBackgroundColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>选中背景色</Col>
      </Row>
      {/*<Row style={{alignItems: 'center'}}>
        <Col span={3}>
          <Form.Item name={['top', 'selectIsBorder']} label="" style={{marginBottom: 0}}>
            <XCheckbox />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={['top', 'selectBorderColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={17}>选中项横线</Col>
      </Row>
      <Row>
        <Col span={3} />
        <Col span={12}>
          <Form.Item name={['top', 'selectBorderWidth']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['top', 'selectBorderSolid']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="solid"
              style={{width:'100px'}}
              options={borderOpt}
            />
          </Form.Item>
        </Col>
      </Row>*/}
      <div>文本</div>
      <Row>
        <Col span={3}>
          <Form.Item name={['top', 'selectFontColor']} label="" style={{marginBottom: 0}}>
            <SketchPicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['top', 'selectFontSize']} label="" style={{marginBottom: 0}}>
            <Input style={{width: '120px'}} suffix="px" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={['top', 'selectFontWeight']} label="" style={{marginBottom: 0}}>
            <Select
              defaultValue="normal"
              style={{width:'100px'}}
              options={fontWeightOpt}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
};
export default TopStyles;
