export const createNotification = () => {
  Notification.requestPermission().then(function (status) {
    if (status === 'denied') {
      console.log('拒绝显示系统通知');
      return;
    }
    if (status === 'default') {
      console.log('未授权显示系统通知');
      return;
    }
    console.log('允许显示系统通知');
    let url = window.location.href.replace(/http/, "ws");
    url = url.substring(0, url.indexOf("/", 7)) + "/message/Notification";
    //url = "ws://localhost/message/Notification";
    const notificationClient = new WebSocket(url);
    notificationClient.onmessage = (result) => {
      if (!result.data.startsWith("{"))
        return;
      let data = JSON.parse(result.data);
      for (let i in data)
        if (!data[i])
          delete data[i];
      console.log("接收到来自系统的通知");
      let notification = new Notification(data.title, data);
      if (data.link)
        notification.onclick = () => {
          window.open(data.link,"Notification");
        };
    }
    const timer = window.setInterval(() => {
      if (notificationClient.readyState == 1)
        notificationClient.send("");
      else
        window.clearInterval(timer);
    }, 50000);
  });

}
