import localStorage from "utils/localStorage";
export function getAuthority() {
    let result: any = {
        tenant: 0,
        project: 0
    }
    if (localStorage.getItem('T_adminAble') == "true") {
        result.tenant = 1;
    }
    if (localStorage.getItem('P_adminAble') == "true") {
        result.project = 1;
    }
    
    return result;
}