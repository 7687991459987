class Segment {
  private readonly _plainObject: any;

  constructor(segment: any) {
    this._plainObject = segment;
    Object.assign(this, segment);
  }

  getPlainObject() {
    return this._plainObject;
  }

  displayName() {
  }

  filterClause(): any {
  }

  isActive() {
  }
}

export default Segment;
