export const borderOpt = [
  {
    value: 'solid',
    label: '实线',
  },
  {
    value: 'dashed',
    label: '虚线',
  }
];
export const fontWeightOpt = [
  {
    value: 'normal',
    label: '常规',
  },
  {
    value: 'bold',
    label: '加粗',
  }
];
