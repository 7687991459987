import {all, call, put, select} from "redux-saga/effects";
import {getAppStoreName} from "constants/AppConstants";
import localStorage, {getTenant} from "utils/localStorage";
import {updateAppStore} from "actions/pageActions";
import AppsmithConsole from "utils/AppsmithConsole";
import {getAppData, getAppStoreData, getCanvasWidgets} from "selectors/entitiesSelector";
import {getCurrentGitBranch} from "selectors/gitSyncSelectors";
import {getCurrentApplicationId} from "selectors/editorSelectors";
import type {AppStoreState} from "reducers/entityReducers/appReducer";
import {LOG_CATEGORY, Severity} from "entities/AppsmithConsole";
import moment from "moment";
import API from "api/Api";
import type {
  TClearStoreDescription,
  TRemoveValueDescription,
  TStoreValueDescription,
} from "workers/Evaluation/fns/storeFns";
import {ApiResponse} from "../../api/ApiResponses";
import _ from "lodash";
import {executeActionTriggers} from "../../ce/sagas/ActionExecution/ActionExecutionSagas";
import {ReduxActionTypes} from "../../ce/constants/ReduxActionConstants";
import {ExecutionType} from "../../ce/workers/Evaluation/Actions";
import {X_PREVIEW_USER} from "@byk/store/XReducers";
import setWidgetValue from "../../ce/sagas/ActionExecution/SetWidgetValue";
import {specialKey} from "../../workers/Evaluation/fns";
import {apiPath} from "@byk/routes";
import {getUrlParams} from "@byk/utils/Utils";

type StoreOperation =
  | TStoreValueDescription
  | TClearStoreDescription
  | TRemoveValueDescription;


const funName:any = {
  'closeModal': (args:any)=>{
    return {
      type: 'CLOSE_MODAL',
      payload: {
        modalName: args[0]
      }
    }
  },
  'showModal': (args:any)=>{
    return {
      type: 'SHOW_MODAL_BY_NAME',
      payload: {
        modalName: args[0]
      }
    }
  },
  'showAlert': (args:any)=>{
    return {
      type: 'SHOW_ALERT',
      payload: {
        message: args[0],
        style: args[1].trim()
      }
    }
  },
  'navigateTo': (args:any)=>{
    return {
      type: 'NAVIGATE_TO',
      payload: {
        pageNameOrUrl: args[0],
        target: args[2].trim(),
        params: {}
      },
      executionType: ExecutionType.PROMISE,
    }
  }
};
function reScript(arg:any){
  let jsstr = arg;
  const f_name = jsstr.substring(0, jsstr.indexOf('('));
  const f_args = jsstr.substring(jsstr.indexOf('(')+1, jsstr.indexOf(')')).replace(/'/g, '').split(',');
  //console.log('jsstr', jsstr, f_name, f_args);
  if(!funName[f_name]){
    return jsstr;
  }
  return funName[f_name](f_args);
}
// @ts-ignore
export function* reEventsActions(actions:any, reqParams:any):Record<string, unknown>{
  const {actionId, dictId, currPage, pageSize, action, apiTarget} = actions;
  const appData = yield select(getAppData);
  if(actionId === 'dict'){//配置字典时，与绑定事件时的api区分开
    const dictResult: ApiResponse&{result:any} = yield call(()=>{
      return API.get(`${apiPath}/${getTenant()}/subapp/SysDataDictItem/list`, {
        dict: dictId,
        enable:true,
        asc: "sort,createDate" })
    })
    const handleDictResult = dictResult.result&&dictResult.result.map((i:any)=>{
      return {
        id: i.id,
        label: i.name,
        value: i.code,
        specialMark: true
      }
    });
    return {
      data: handleDictResult||[],
      widgetId: apiTarget,
      event: actionId
    };
  }

  let canvasDsl: Record<string, unknown&{ widgetName: string }> = yield select(getCanvasWidgets);
  const resAction: ApiResponse = yield call(()=>{
    return API.get(`${apiPath}/${getTenant()}/subapp/AppAction`, {
      id: actionId
    });
  });

  const _action:any = resAction as any;
  if(_.isEmpty(_action.result)){
    return [];
  }
  let {url, requestMsgFields, requestType, serviceCode, responseMsgFields, elementId, pageName, widgetId, widgetName, event} = _action.result?.[0];
  let reqMsgFieldsObj = requestMsgFields&&requestMsgFields!=='null'?JSON.parse(requestMsgFields):[];
  let resMsgFieldsObj = responseMsgFields&&responseMsgFields!=='null'?JSON.parse(responseMsgFields):[];
  let newReqValues = {
    ...reqParams
  };

  let newReqParams:any = {};
  let _page:string[] = [];
  _.each(reqMsgFieldsObj, (i:any)=>{
    if(i.startsWith&&i.startsWith('__')){
      return true;
    }
    let key = i;
    if(_.isObject(i)){
      key = _.keys(i)[0];
    }
    _page.push(key);
  });
  let _context = reqMsgFieldsObj.filter((i:any)=>{
    return i.startsWith&&i.startsWith('__context');
  });
  let _constant = reqMsgFieldsObj.filter((i:any)=>{
    return i.startsWith&&i.startsWith('__constant');
  });
  //匹配入参
  //常量
  if(!_.isEmpty(_constant)){
    _.each(_constant, (i:any)=>{
      newReqParams[i] = i.split('.').splice(-1).join();
    })
  }
  //上下文
  if(!_.isEmpty(_context)){
    _.each(_context, (i:any)=>{
      let _iKey = '', _iPath='';
      if(i.startsWith('__context.User.')){
        _iKey = i.replace('__context.User.', '__context.user.');
      }else if(i.startsWith('__context.Url.param.')){
        _iKey = i.replace('__context.Url.param.', '__context.URL.queryParams.');
      }else{
        //
      }
      _iPath = _iKey.substring(10);
      if(_iKey&&_iPath){
        newReqParams[i] = _.get(appData, `${_iPath}`)
      }

    })
  }
  //页面元素
  if(!_.isEmpty(_page)){
    const reqMapFn = (data:any, obj:any)=>{
      _.each(data, (key:any)=>{
        if(typeof key == 'string'){
          let _key:any = key;
          let _values:any = {
            ...newReqValues,
            ...newReqValues.apiParams
          }
          if(_key.endsWith('currPage')&&requestType=='get'){
            obj[key] = currPage??1;
          }else if(_key.endsWith('pageSize')&&requestType=='get'){
            obj[key] = pageSize??10;
          }else{
            if(requestType=='put'){
              obj[key] = (_values[_key]===''||_values[_key]===undefined)?null:_values[_key];
            }
            if(requestType!='put'&&_values[_key]!==''&&_values[_key]!==undefined&&_values[_key]!==null){
              obj[key] = _values[_key];
            }
          }
        }else if(typeof key == 'object'){
          _.each(key, (value, valueKey)=>{
            obj[valueKey] = {};
            reqMapFn(value, obj[valueKey]);
          })
        }
      })
    };
    reqMapFn(_page, newReqParams);
  }
  //是否绑定服务得入参处理 动态表单和配置的入参与映射入参做合并
  if(_.isEmpty(serviceCode)){
    newReqParams = {
      ...newReqValues,
      ...newReqValues?.globalContext,
      ...newReqValues?.apiParams
    }
    delete newReqParams.globalContext;
    delete newReqParams.apiParams;
  }else{
    let mappingPageParams:Record<string, unknown> = {};
    //取页面元素入参
    if(!_.isEmpty(_page)){
      _.each(_page, (i:any)=>{
        if(['get', 'exported'].includes(requestType)){
          mappingPageParams[i] = newReqParams[i]??'';
        }else{
          mappingPageParams[i] = newReqParams[i] === ''?null:newReqParams[i];
        }

      })
    }
    //取常量和上下文入参
    _.each(newReqParams, (v:any, k:any)=>{
      if(k.startsWith('__context')||k.startsWith('__constant')){
        mappingPageParams[k] = v;
      }
    })
    if(newReqValues.globalContext){
      mappingPageParams = {
        ...mappingPageParams,
        ...newReqValues.globalContext
      }
    }
    //动作框里的输入参数
    if(newReqValues.eventParams){
      mappingPageParams = {
        ...mappingPageParams,
        ...newReqValues.eventParams
      }
    }
    newReqParams = mappingPageParams;
  }

  if(action=="exported"){
    let _name=`${pageName||Date.now()}.xlsx`;
    newReqParams.export=_name;
    let _url=API.methods("exported", `${apiPath}/${getTenant()}/${url}`, newReqParams);
    return _url;
  }
  const res: ApiResponse = yield call(()=>{
    return API.methods(requestType, `${apiPath}/${getTenant()}/${url}`, newReqParams);
  });

  const res2:any = res as any;
  const res2Data = res2.data?.body?.data[0];
  //输出字段对应的组件类型
  let widgetTypeMap:Record<string, string> = {};
  if(res2Data?.['__widgetTypeMap__']){
    widgetTypeMap = JSON.parse(res2Data['__widgetTypeMap__']);
  }
  //接口报错直接返回
  if(!res2Data.success){
    return {
      data: [],
      size: res2Data.size,
      success: res2Data.success,
      message: res2Data.message
    };
  }

  if(requestType == 'delete'){//跳过回写store
    return {
      data: null,
      size: null,
      success: res2Data.success,
      message: res2Data.message
    };
  }
  if(res2Data&&res2Data.ext){
    const { user } = res2Data.ext;
    if(!_.isEmpty(user)){
      yield put({
        type: X_PREVIEW_USER,
        data: {...res2Data.ext}
      })
      yield put({
        type: ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
        payload: user||{},
      })
      yield put({
        type: ReduxActionTypes.FETCH_APPLICATION_INIT,
        payload: {
          applicationId: getUrlParams().appId
        },
      })
    }
  }
  if(typeof res2Data == 'string'){
    let widgetId:string = elementId?.split('_')[0];
    let widgetName = canvasDsl[widgetId]?.widgetName||'';
    yield call(
      // @ts-ignore
      executeActionTriggers,
      reScript(`showAlert('${widgetName} ${res2Data}', 'error')`),
      'ON_CLICK',
      undefined,
    );
  }

  //begin 处理接口输出映射
  const res2DataResult:Array<any> = res2Data?.result||[];
  let keyMap:Record<string, any> = {//默认值，不需映射
    id: "id",
    label: "label",
    value: "value",
    key: "key",
    children: "children",
    field: 'field',
    fieldName: 'fieldName',
    fieldType: 'fieldType',
    options: 'options',
    required: 'required',
    message: 'message'
  };
  _.each(resMsgFieldsObj, (values:any)=>{
    if(_.isString(values)){
      keyMap[values] = (values.endsWith('.label')||values.endsWith('.value'))?values:(values.split('.').splice(-1).join());
    }
    if(_.isObject(values)){
      let keys = _.keys(values);
      _.each(keys, (i:any)=>{
        keyMap[i] = i;
      })

    }
  })

  //end
  let respondData:any = {
    size: res2Data.size,
    success: res2Data.success,
    message: res2Data.message,
    widgetId,
    widgetName,
    event,
    widgetTypeMap,
    serviceCode
  };
  if(_.isEmpty(serviceCode)){
    return {
      data: res2DataResult,
      ...respondData
    };
  }
  //输出时把对应类型的值转换
  let res2DataResultHandle:Array<any> = yield call(setWidgetValue, {
    res2DataResult,
    keyMap,
    widgetTypeMap
  })

  return {
    data: res2DataResultHandle,
    ...respondData
  };
}
// @ts-ignore
export function* handleStoreOperations(triggers: StoreOperation[]) {
  let handleTriggers:any = [...triggers];
  if(handleTriggers[0].payload.key === specialKey){
    yield put({
      type: ReduxActionTypes.X_ACTION_EXECUTION,
      payload: {
        dynamicString: handleTriggers[0].payload.value.dynamicString,
        globalContext: handleTriggers[0].payload.value.globalContext
      }
    })
    return ;
  }
  const applicationId: string = yield select(getCurrentApplicationId);
  const branch: string | undefined = yield select(getCurrentGitBranch);
  const appStoreName = getAppStoreName(applicationId, branch);
  const existingLocalStore = localStorage.getItem(appStoreName) || "{}";
  let parsedLocalStore = JSON.parse(existingLocalStore);
  let currentStore: AppStoreState = yield select(getAppStoreData);
  const logs: string[] = [];
  //api 处理，先查action，在发请求

  if(handleTriggers[0].type!="CLEAR_STORE"){
    let lists:any[] = yield all(
      handleTriggers.map((i:any, index:number)=>{
        const {reqParams, actionId, success, fail, currPage, pageSize, recordReg} = i.payload.value;
        let result:any = i.payload.value;
        if(reqParams&&actionId){
          result = call(reEventsActions, {
            actionId, success, fail, currPage, pageSize, recordReg
          }, reqParams);
        }
        return result;
      })
    );
    _.each(lists, (i:any, index:number)=>{
      //console.log('---', i);
      if(handleTriggers[index]){
        handleTriggers[index].payload.value = i.data;
        handleTriggers[index].payload.size = i.size||10;
      }
    })
  }


  // const {reqParams, actionId, success, fail} = handleTriggers[0].payload.value;
  // if(reqParams&&actionId){
  //   //查action,获取请求url，入参，赋值
  //   const _result:ApiResponse = yield reEventsActions({
  //     actionId, success, fail
  //   }, reqParams);
  //   console.log('+++', _result);
  //   handleTriggers[0].payload.value = _result as any;
  // }
  //console.log('+=+=+=+=', handleTriggers);
  for (const t of handleTriggers) {
    const { type } = t;
    if (type === "STORE_VALUE") {
      const { key, persist, value, size } = t.payload;
      if(value != null){
        if (persist) {
          parsedLocalStore[key] = value;
          parsedLocalStore[`${key}Size`] = size;
        }
        currentStore[key] = value;
        currentStore[`${key}Size`] = size;
        logs.push(`storeValue('${key}', '${value}', ${persist})`);
      }
    } else if (type === "REMOVE_VALUE") {
      const { key } = t.payload;
      delete parsedLocalStore[key];
      delete currentStore[key];
      logs.push(`removeValue('${key}')`);
    } else if (type === "CLEAR_STORE") {
      parsedLocalStore = {};
      currentStore = {};
      logs.push(`clearStore()`);
    }
  }
  yield put(updateAppStore(currentStore));
  const storeString = JSON.stringify(parsedLocalStore);
  localStorage.setItem(appStoreName, storeString);
  AppsmithConsole.addLogs(
    logs.map((text) => ({
      text,
      severity: Severity.INFO,
      category: LOG_CATEGORY.USER_GENERATED,
      timestamp: moment().format("hh:mm:ss"),
      isExpanded: false,
    })),
  );
}
