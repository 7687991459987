import {
  JoinCondition,
  JoinFields,
  JoinObject,
  JoinStrategy,
  StructuredQueryType
} from "@byk/pages/QueryBuilder/lib/metadata/types";
import {MBQLObjectClause} from "./MBQLClause";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import Dimension, {FieldDimension} from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import DimensionOptions from "@byk/pages/QueryBuilder/lib/metadata/DimensionOptions";
import {isFieldLiteral} from "@byk/pages/QueryBuilder/utils/field-ref";

const JOIN_OPERATORS = ["=", ">", "<", ">=", "<=", "!="];

export const JOIN_STRATEGY_OPTIONS = [
  {
    value: "LEFT_JOIN",
    name: "左外连接",
    icon: "JoinLeftOuter",
  }, // default
  {
    value: "RIGHT_JOIN",
    name: "右外连接",
    icon: "JoinRightOuter",
  },
  {
    value: "INNER_JOIN",
    name: "内连接",
    icon: "JoinInner",
  },
  // {
  //   value: "full-join",
  //   name: "完全外部联接",
  //   icon: "JoinFullOuter",
  // },
];
const PARENT_DIMENSION_INDEX = 1;
const JOIN_DIMENSION_INDEX = 2;

export default class Join extends MBQLObjectClause {
  strategy: JoinStrategy = "LEFT_JOIN";
  alias: any;
  condition: JoinCondition | null | undefined;
  fields: JoinFields | null | undefined;
  modelId: any;

  constructor(
    mbql: Record<string, any>,
    index?: number | null | undefined,
    query?: StructuredQuery) {
    super(index, query);
    Object.assign(this, mbql);
  }

  parentQuery() {
    return this.query();
  }

  set(join: any) {
    return new Join(join, this._index, this._query);
  }

  setIndex(index: any) {
    this._index = index;
    return this;
  }

  parentTable() {
    const parentQuery = this.parentQuery();
    return parentQuery && parentQuery.model();
  }

  strategyOption() {
    let joinStrategy = JOIN_STRATEGY_OPTIONS[0];
    JOIN_STRATEGY_OPTIONS.forEach(item => {
      if (item.value === this.strategy) {
        joinStrategy = item;
      }
    })
    return joinStrategy;
  }

  strategyOptions(): any[] {
    return JOIN_STRATEGY_OPTIONS;
  }

  setStrategy(strategy: JoinStrategy) {
    return this.set({...this, strategy});
  }

  /**
   * Replaces the aggregation in the parent query and returns the new StructuredQuery
   */
  replace(join: Join | JoinObject): StructuredQuery {
    return this._query.updateJoin(this._index, join);
  }

  parent() {
    return this.replace(this);
  }

  joinSourceTableId() {
    return this.modelId
  }

  joinSourceQuery(): StructuredQueryType | null | undefined {
    // @ts-ignore
    return this["source-query"];
  }

  joinedTable() {
    let tableId = this.joinSourceTableId();
    if (tableId) {
      return this.query().getModelById(tableId);
    } else {
      return null;
    }
  }

  setJoinSourceTableId(tableId: any): Join {
    // @ts-ignore
    if (tableId !== this.modelId) {
      const join = this.set({
        ...this,
        "modelId": tableId,
        condition: null,
      });

      if (!join.alias) {
        return join.setDefaultAlias();
      } else {
        return join;
      }
    } else {
      return this;
    }
  }

  remove(): StructuredQuery {
    return this._query.removeJoin(this._index);
  }

  setDefaultAlias(): Join {
    const table = this.joinedTable();
    const alias = table && table.name;
    return this.setAlias(alias);
  }

  _uniqueAlias(name: string): string {
    const usedAliases = new Set(
      this.query()
        .joins()
        .map(join => join.alias)
        .filter(alias => alias !== this.alias),
    );
    // alias can't be same as parent table name either
    const parentTable = this.parentTable();

    if (parentTable) {
      usedAliases.add(parentTable.name);
    }

    for (let index = 1; ; index++) {
      const alias = index === 1 ? name : `${name}_${index}`;

      if (!usedAliases.has(alias)) {
        return alias;
      }
    }
  }

  setAlias(alias: any) {
    alias = this._uniqueAlias(alias);

    if (alias !== this.alias) {
      let join = this.set({...this, alias});
      return join;
    }

    return this;
  }

  setDefaultCondition(): Join {
    return this;
  }

  setFields(fields: JoinFields) {
    return this.set({...this, fields});
  }

  getConditions() {
    if (!this.condition) {
      return [];
    }

    if (this.isSingleConditionJoin()) {
      return [this.condition];
    }

    const [, ...conditions] = this.condition;
    return conditions;
  }

  // CONDITIONS
  isSingleConditionJoin() {
    const {condition} = this;
    return Array.isArray(condition) && JOIN_OPERATORS.includes(condition[0]);
  }

  isMultipleConditionsJoin() {
    const {condition}: any = this;
    return Array.isArray(condition) && condition[0] === "and";
  }

  parentDimensions(): any[] {
    if (!this.condition) {
      return [];
    }

    return this.isSingleConditionJoin()
      ? [this._getParentDimensionFromCondition(this.condition)]
      : this._getParentDimensionsFromMultipleConditions();

    // let parentOptions: any[] = this.parentDimensionOptions();
    // let dimensions: any[] = [];
    // if (this.isSingleConditionJoin()) {
    //   parentOptions.forEach(item => {
    //     let fieldId = this.condition && this.condition[1] && this.condition[1][1];
    //     if (item.fieldRef[1] == fieldId) {
    //       dimensions.push(item)
    //     }
    //   })
    // }
    //
    // return dimensions;
  }

  _getParentDimensionFromCondition(condition: any) {
    const [, parentDimension] = condition;
    return parentDimension && this.query().parseFieldReference(parentDimension);
  }

  _getParentDimensionsFromMultipleConditions() {
    if (this.condition) {
      const [, ...conditions] = this.condition;
      return conditions.map(condition =>
        this._getParentDimensionFromCondition(condition),
      );
    }

    return [];
  }

  parentDimensionOptions() {
    const query = this.query();
    const dimensions = query.dimensions();
    const options: any = {
      count: dimensions.length,
      dimensions: dimensions,
      fks: [],
      preventNumberSubDimensions: true,
    };
    // add all previous joined fields
    const joins = query.joins();

    for (let i = 0; i < this.index(); i++) {
      const fkOptions = joins[i].joinedDimensionOptions();
      options.count += fkOptions.count;
      options.fks.push(fkOptions);
    }

    return new DimensionOptions(options);
  }

  joinDimensions(): any[] {
    if (!this.condition) {
      return [];
    }

    return this.isSingleConditionJoin()
      ? [this._getJoinDimensionFromCondition(this.condition)]
      : this._getJoinDimensionsFromMultipleConditions();
  }

  _getJoinDimensionFromCondition(condition: any) {
    const [, , joinDimension] = condition;
    return (
      joinDimension &&
      this.query().parseFieldReference(joinDimension)
    );
  }

  _getJoinDimensionsFromMultipleConditions() {
    if (this.condition) {
      const [, ...conditions] = this.condition;
      return conditions.map(condition =>
        this._getJoinDimensionFromCondition(condition),
      );
    }
    return [];
  }

  joinDimensionOptions() {
    const dimensions = this.joinedDimensions();
    return new DimensionOptions({
      count: dimensions.length,
      dimensions: dimensions,
      fks: [],
    });
  }

  getDimensions(): any[] {
    const conditions: any[] = this.getConditions();
    return conditions.map(condition => {
      const [, parentDimension, joinDimension] = condition;
      return [
        parentDimension,
        joinDimension,
      ];
    });
  }

  getConditionByIndex(index: any) {
    if (!this.condition) {
      return null;
    }

    if (this.isSingleConditionJoin() && !index) {
      return this.condition;
    }

    if (this.isMultipleConditionsJoin()) {
      const [, ...conditions] = this.condition;
      return conditions[index];
    }

    return null;
  }

  _getOperatorOrDefault(condition: any) {
    return condition?.[0] ?? "=";
  }

  setCondition(condition: any): Join {
    return this.set({...this, condition});
  }

  setConditionByIndex({index = 0, condition}: any): Join {
    if (!this.condition) {
      return this.setCondition(condition);
    }

    if (this.isSingleConditionJoin()) {
      if (index === 0) {
        return this.setCondition(condition);
      } else {
        return this.setCondition(["and", this.condition, condition]);
      }
    }

    const conditions = [...this.condition];
    conditions[index + 1] = condition;
    return this.setCondition(conditions);
  }

  setParentDimension(param: { index: any; dimension: any }) {
    let {index, dimension} = param;
    const condition = this.getConditionByIndex(index);
    const operator = this._getOperatorOrDefault(condition);

    const join = condition ? condition[JOIN_DIMENSION_INDEX] : null;
    const newCondition = [operator, dimension, join];
    return this.setConditionByIndex({
      index,
      condition: newCondition,
    });
  }

  setJoinDimension(param: { index: any; dimension: any }) {
    let {index, dimension} = param;
    const condition = this.getConditionByIndex(index);
    const operator = this._getOperatorOrDefault(condition);

    const parent = condition ? condition[PARENT_DIMENSION_INDEX] : null;
    const newCondition = [operator, parent, dimension];
    return this.setConditionByIndex({
      index,
      condition: newCondition,
    });
  }

  setOperator(index: number, operator: any) {
    if (index == null || !this.getConditionByIndex(index)) {
      return this.setConditionByIndex({
        condition: [operator, null, null],
      });
    }

    // @ts-ignore
    const [_oldOperator, ...args] = this.getConditionByIndex(index);

    return this.setConditionByIndex({
      index,
      condition: [operator, ...args],
    });
  }

  joinedDimensionOptions(dimensionFilter: (d: Dimension) => boolean = () => true,): DimensionOptions {
    const dimensions = this.joinedDimensions().filter(dimensionFilter);
    return new DimensionOptions({
      name: this.alias,
      icon: "join_left_outer",
      dimensions: dimensions,
      fks: [],
      count: dimensions.length,
    });
  }

  fieldsDimensions(): any[] {
    if (this.fields === "all") {
      return this.joinedDimensions();
    } else if (Array.isArray(this.fields)) {
      return this.fields.map(f => this.query().parseFieldReference(f));
    } else {
      return [];
    }
  }

  private joinedDimensions(): any[] {
    const table = this.joinedTable();
    return table
      ? table.dimensions().map(dimension => this.joinedDimension(dimension))
      : [];
  }

  joinedDimension(dimension: Dimension) {
    if (dimension instanceof FieldDimension) {
      return dimension.withJoinAlias(this.alias).setQuery(this.query());
    }

    console.warn("Don't know how to create joined dimension with:", dimension);
    return dimension;
  }

  hasGaps() {
    const parentDimensions = this.parentDimensions();
    const joinDimensions = this.joinDimensions();
    return (
      parentDimensions.length === 0 ||
      joinDimensions.length === 0 ||
      parentDimensions.length !== joinDimensions.length ||
      parentDimensions.some(dimension => dimension == null) ||
      joinDimensions.some(dimension => dimension == null)
    );
  }

  isValid() {
    if (!this.condition || !this.joinedTable() || this.hasGaps()) {
      return false;
    }

    const dimensionOptions = this.parent().dimensionOptions();
    const dimensions = [...this.parentDimensions(), ...this.joinDimensions()];
    return dimensions.every(
      dimension =>
        dimensionOptions.hasDimension(dimension) ||
        dimensionOptions.hasDimension(dimension.getMLv1CompatibleDimension()) ||
        // For some GUI queries created in earlier versions of Metabase,
        // some dimensions are described as field literals
        // Usually it's [ "field", field_numeric_id, null|object ]
        // And field literals look like [ "field", "PRODUCT_ID", {'base-type': 'type/Integer' } ]
        // These literals are not present in dimension options, but still work
        // As a workaround, we just skip field literals if they're not present in dimension options
        isFieldLiteral(dimension.mbql()),
    );
  }

  addEmptyDimensionsPair() {
    if (!this.condition) {
      return this.setCondition([]);
    }

    if (this.isSingleConditionJoin()) {
      return this.setCondition(["and", this.condition, []]);
    } else {
      return this.setCondition([...this.condition, []]);
    }
  }

  removeCondition(index: number) {
    if (index == null || !this.getConditionByIndex(index)) {
      return this;
    }

    if (this.isSingleConditionJoin()) {
      return this.setCondition(null);
    }

    if (this.condition) {
      const filteredCondition = this.condition.filter((_, i) => {
        // Adding 1 because the first element of a condition is an operator ("and")
        return i !== index + 1;
      });
      const [, ...conditions] = filteredCondition;
      const isSingleNewCondition = conditions.length === 1;

      if (isSingleNewCondition) {
        return this.setCondition(conditions[0]);
      }

      return this.setCondition(filteredCondition);
    }

    return this;
  }
}
