export type DatePickerType = "DATE_PICKER" | "DATE_RANGE_PICKER";
export const YYYY = "YYYY"
export const YYYY_MM = "YYYY-MM"
export const YYYY_MM_DD = "YYYY-MM-DD"
export const YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:mm"
export const YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss"
export const HH_MM_SS = "HH:mm:ss"
export const HH_MM = "HH:mm"
export const pickerMap={
  "YYYY":"year",
  "YYYY-MM":"month",
  "YYYY-MM-DD":"date",
  "YYYY-MM-DD HH:mm":"showHour",
  "YYYY-MM-DD HH:mm:ss":"showTime",
  "HH:mm:ss":"HH:mm:ss",
  "HH:mm":"HH:mm"
}
export enum TimePrecision {
  NONE = "None",
  MILLISECOND = "millisecond",
  MINUTE = "minute",
  SECOND = "second",
}
