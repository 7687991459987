import {Checkbox, Input, Space, Tabs, Tree} from "antd";
import {MinusOutlined, PaperClipOutlined, PlusOutlined} from "@ant-design/icons/lib";
import type {DataNode, TreeProps} from 'antd/es/tree';
import React, {useEffect, useRef, useState} from "react";
import {uuid4} from "@sentry/utils";
import Api from 'api/Api';
import {
  getCurrentApplicationId,
  getCurrentIframeId,
  getCurrentPageId,
  getFrameDesignLoyoutId,
} from "../../../selectors/editorSelectors";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getCurrentPageWidgets} from "../../../selectors/entitiesSelector";
import _ from 'lodash';
import TopStyles from "./TopStyles";
import LeftStyles from "./LeftStyles";
import UploadPic from "@byk/pages/components/UploadPic";
import {useParams} from "react-router";
import localStorage from "utils/localStorage";
import {X_LINKPAGESET, X_LINKPANNELOFFSET, X_OPENLINKPANNEL} from "@byk/store/XReducers";
import axios from "axios";

const ThreeRows = styled.div`
  display:flex;
  gap: 10px;
  .flex1{
    flex: 1;
    text-align:center;
    line-height:40px;
    background:#f4f4f4;
  }
  .flex1_active{
    color:#f00;
  }
`;
const XTitle = styled.div`
  font-size: 16px;
  display:flex;
  justify-content: space-between;
  margin: 10px 0;
`;
const XWrapper = styled.div<{ marginTop?: string, borderTop?: string }>`
  padding: 0 10px;
  margin-top: ${(props) => props.marginTop ?? 0};
  border-top: ${(props) => props.borderTop ?? 'none'};
`;


export const addMenuKey: any = (valueMenus: any) => {
  if (_.isEmpty(valueMenus)) {
    return [];
  }
  return _.sortBy(valueMenus, 'sort').map((i: any) => {
    return {
      ...i,
      key: i.menuKey || i.key,
      children: i.children && addMenuKey(i.children)
    }
  })
}

const App = () => {
  //state.ui.pageWidgets
  const dispatch = useDispatch();
  const [gData, setGData] = useState([]);
  const gDataCache = useRef([]);
  const appId = useSelector(getCurrentApplicationId);
  const iframeId = useSelector(getCurrentIframeId);
  const [threeActive, setThreeActive] = useState(0);
  const topFormRef = useRef({});
  const leftFormRef = useRef({});
  const logoRef = useRef('');
  const currentPageId = useSelector(getCurrentPageId);
  const pageId = useSelector(getCurrentPageId);
  const currentLayoutId = useSelector(getFrameDesignLoyoutId);
  const currentPage = useSelector(getCurrentPageWidgets);
  const [topFormInitValues, setTopFormInitValues] = useState({});
  const [leftFormInitValues, setLeftFormInitValues] = useState({});
  const [otherForm, setOtherForm] = useState<{
    global: boolean,
    notify: boolean,
    message: boolean
  }>({
    global: false,
    notify: false,
    message: false
  });
  const paramsObj = useParams<{ pageId: string }>();
  const layoutId = useSelector(state => {
    const pagesList: any = state.entities.pageList.pages.filter((i: any) => {
      return i.pageId == paramsObj.pageId;
    });
    return pagesList?.[0]?.loyoutId;
  });
  const linkPannel = useSelector((state: any) => {
    return state.XReducers.linkPannelSet;
  })
  const setLinkPannel = (data: any) => {
    dispatch({
      type: X_LINKPAGESET,
      data
    });
  }
  const setLinkOpen = (data: boolean) => {
    dispatch({
      type: X_OPENLINKPANNEL,
      data
    })
  }
  const onDragEnter: TreeProps['onDragEnter'] = info => {
    console.log(info);
  };
  const loop = (
    data: any,
    key: React.Key,
    callback: (node: any, i: number, data: any) => void,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children!, key, callback);
      }
    }
  };
  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const data = gData ? _.cloneDeep(gData) : [];
    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).children || []).length > 0 && // Has children
      (info.node as any).expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: any[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setGData(data as any);
  };
  const temp: any = {
    title: "",
    children: [],
    actionType: '',
    topage: '',
    topageid: '',
    toUrl: '',
    topagetype: '',
    linkParams: '',
  };
  const onAdd = (key?: any) => {
    const data: any = gData ? _.cloneDeep(gData) : [];
    let menuKey = uuid4();
    if (key) {
      loop(data, key, (item) => {
        const children = Array.isArray(item.children) ? [...item.children] : []
        children.push({
          key: menuKey,
          menuKey,
          pid: item.key,
          ...temp
        })
        item.children = children
      });
    } else {
      data.push({
        key: menuKey,
        menuKey,
        ...temp
      })
    }
    setGData(data);
  };
  const onDelete = (key: any) => {
    const data = gData ? _.cloneDeep(gData) : [];
    loop(data, key, (_, index, arr) => {
      arr.splice(index, 1);
    });
    setGData(data as any);
  };
  const onOpen = (nodeData: any) => {
    //console.log(nodeData);
    setLinkPannel({
      ...linkPannel,
      key: nodeData.key,
      appid: appId,
      iframeid: iframeId,
      actionType: nodeData.actionType,
      topage: nodeData.topage,
      topageid: nodeData.topageid,
      toUrl: nodeData.toUrl,
      topagetype: nodeData.topagetype,
      linkParams: nodeData.linkParams,
      icon: nodeData.icon,
      save: false
    })
    setLinkOpen(true);
  }
  const onOpenMenu = (nodeData: any) => {
    setLinkPannelOffset({
      left: 'auto',
      right: '293px',
      top: '324px',
      bottom: 'auto',
      isIcon: true
    })
    onOpen(nodeData);
  }
  const onOpenOther = (arg: any) => {
    let otherChild: any = _.find(gData, {key: 'other'});
    let global = _.find(otherChild.children, {key: arg.key});
    //console.log('onOpenOther==', global);
    setLinkPannelOffset({
      left: 'auto',
      right: '293px',
      top: 'auto',
      bottom: '10px',
      isIcon: false
    })
    onOpen({
      key: global.key,
      topageid: global.topageid,
      topage: global.topage,
      topagetype: global.topagetype,
      icon: global.icon,
    })
  }
  // input 改变
  const onChange = (e: any, key: any) => {
    const data: any = gData ? _.cloneDeep(gData) : [];
    loop(data, key, (item) => {
      item.title = e.target.value;
    });
    setGData(data);
  };
  useEffect(() => {
    if (linkPannel.key && linkPannel.save) {
      const data: any = gData ? _.cloneDeep(gData) : [];
      loop(data, linkPannel.key, (item) => {
        item.topage = linkPannel.topage;
        item.topageid = linkPannel.topageid;
        item.topagetype = linkPannel.topagetype;
        item.appid = linkPannel.appid;
        item.iframeid = linkPannel.iframeid;
        item.icon = linkPannel.icon;
        item.actionType = linkPannel.actionType;
        item.toUrl = linkPannel.toUrl;
        item.linkParams = linkPannel.linkParams;
      });
      setGData(data);
    }
  }, [linkPannel]);
  const gDataAddSort = (data: any, pid?: any) => {
    return data.map((i: any, index: number) => {
      return {
        ...i,
        menuKey: i.key,
        key: undefined,
        sort: index + 1,
        pid: pid ? pid : 0,
        children: gDataAddSort(i.children || [], i.key)
      }
    })
  };
  let source: any = null;
  const save = (threeActiveNum?:any, gDataInit?:any) => {
    if (!currentLayoutId && !layoutId) {
      return;
    }
    let params = {
      id: currentLayoutId || layoutId,
      pageId: currentPageId,
      dsl: {
        ...currentPage[0],
        menus: gDataAddSort(gDataInit||gData),
        menusType: threeActiveNum??threeActive, //0左，1顶，2左顶
        topForm: topFormRef.current,
        leftForm: leftFormRef.current,
        logo: logoRef.current
      }
    };
    //console.log('save------------------------------', params);
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();
    Api.put(`/byk/platform/rest/LayoutConfig`,
      params,
      undefined,
      {cancelToken: source.token}
    ).then((res: any) => {
      let {dsl} = res?.result?.[0];
      dispatch({
        type: 'X_IFRAMEDSL',
        data: dsl
      })
      localStorage.setItem(`iframe_dsl_${currentPageId}_${currentLayoutId}`, JSON.stringify(dsl));
    })
  }
  const setLinkPannelOffset = (offset: any) => {
    dispatch({
      type: X_LINKPANNELOFFSET,
      data: {...offset}
    })
  }
  useEffect(() => {
    Api.get(`/byk/platform/app/appMenu`, {
      appId: appId
    }).then((res:any)=>{
      const iframeDSL_str = localStorage.getItem(`iframe_dsl_${currentPageId}_${currentLayoutId}`);
      const iframeDSL = iframeDSL_str ? JSON.parse(iframeDSL_str) : {}
      const dsl: any = {
        ...currentPage[0],
        ...iframeDSL
      };
      const menus = res.result;
      const {topForm, leftForm, menusType, logo} = dsl;
      setTopFormInitValues(topForm ?? {});
      topFormRef.current = _.merge({}, topFormRef.current, topForm);

      setLeftFormInitValues(leftForm ?? {});
      leftFormRef.current = _.merge({}, leftFormRef.current, leftForm);
      logoRef.current = logo;

      let _other = _.find(menus, {menuKey: 'other'});
      if (!_.isEmpty(_other)) {
        let _otherForm: any = {}
        _.each(_other.children, (i: any) => {
          _otherForm[i.menuKey] = i.menuCheck;
        });
        setOtherForm(_otherForm);
      }

      let _gData = addMenuKey(menus);
      gDataCache.current = _gData;
      setGData(_gData);
      setThreeActive(menusType ?? 0);
      if(menusType === undefined){
        setThreeActiveFn(0);
        save(0, _gData);
      }
    });


  }, []);

  const delaySave = () => {
    save();
  };

  useEffect(() => {
    if(!_.isEmpty(gData)){
      const gDataIsEqual = _.isEqual(gData, gDataCache.current);
      if(!gDataIsEqual){
        delaySave();
        gDataCache.current = gData;
      }
    }
  }, [gData, threeActive]);

  const setThreeActiveFn = (num: number) => {
    setThreeActive(num);
  }
  const saveThreeActive = (num: number) => {
    save(num);
  }
  const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
  };
  const onValuesChangeTop = (values: any) => {
    topFormRef.current = _.merge({}, topFormRef.current, values);
    save();
  };
  const onValuesChangeLeft = (values: any) => {
    leftFormRef.current = _.merge({}, leftFormRef.current, values);
    save();
  };

  const setLogoRef = (url: string, isClear: boolean = false) => {
    logoRef.current = isClear ? "" : url;
    save();
  }
  const otherChange = (ev: any, key: string) => {
    setOtherForm({
      ...otherForm,
      [key]: ev.target.checked
    })
    const data: any = gData ? _.cloneDeep(gData) : [];
    loop(data, key, (item) => {
      item.menuCheck = ev.target.checked;
    });
    setGData(data);
  }
  return (
    <>
      <XWrapper>
        <XTitle>类型</XTitle>
        <ThreeRows>
          {
            [
              {name: '左', key: 0},
              {name: '顶', key: 1},
              {name: '左+顶', key: 2}
            ].map(i => {
              return (
                <div style={{cursor: "pointer"}} className={`flex1 ${i.key === threeActive && 'flex1_active'}`}
                     key={i.key} onClick={() => {
                  setThreeActiveFn(i.key);
                  saveThreeActive(i.key);
                }}>{i.name}</div>
              )
            })
          }
        </ThreeRows>
      </XWrapper>
      <XWrapper marginTop={'10px'} borderTop={'1px solid #ccc'}>
        <XTitle>LOGO</XTitle>
        <div>
          <UploadPic onEmit={(img: string) => {
            setLogoRef(img);
          }} onEmitClear={() => {
            setLogoRef("", true);
          }} logo={currentPage[0]?.logo}/>
        </div>
      </XWrapper>
      <XWrapper marginTop={'10px'} borderTop={'1px solid #ccc'}>
        <XTitle>
          <div className="x_title_left">菜单</div>
          <div className="x_title_right" onClick={() => {
            onAdd();
          }}><PlusOutlined/></div>
        </XTitle>
        <Tree
          className="draggable-tree"
          defaultExpandAll={true}
          draggable
          blockNode
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          treeData={gData.filter((i: any) => {
            return i.key !== 'other';
          })}
          titleRender={(nodeData: DataNode & { readonly: true }) => {
            return (
              <Space>
                {nodeData.readonly ? (
                  <>
                    {nodeData.title}
                  </>
                ) : (
                  <>

                    {/* <Input prefix={nodeData.icon ? (<Button small={true} icon={nodeData.icon as IconName}/>) : null} */}
                    <Input
                           placeholder={'请输入'}
                           defaultValue={nodeData.title as string}
                           onChange={(e) => onChange(e, nodeData.key)}/>
                    <PaperClipOutlined onClick={() => onOpenMenu(nodeData)}/>
                    <PlusOutlined onClick={() => onAdd(nodeData.key)}/>
                    <MinusOutlined onClick={() => onDelete(nodeData.key)}/>
                  </>
                )}

              </Space>
            )
          }}
        />


      </XWrapper>
      <XWrapper marginTop={'10px'} borderTop={'1px solid #ccc'}>
        <XTitle>外观</XTitle>
        <Tabs
          type="card"
          items={[
            {
              label: '顶部导航',
              key: 'top',
              children: <TopStyles layout={layout} defaultValues={topFormInitValues} onValuesChangeTop={
                (values: any) => {
                  onValuesChangeTop(values);
                }
              }/>
            },
            {
              label: '左侧导航',
              key: 'left',
              children: <LeftStyles layout={layout} defaultValues={leftFormInitValues} onValuesChangeLeft={
                (values: any) => {
                  onValuesChangeLeft(values);
                }
              }/>
            }
          ]}
        />
      </XWrapper>
      <XWrapper marginTop={'10px'} borderTop={'1px solid #ccc'}>
        <XTitle>其他</XTitle>
        <div>
          <Checkbox checked={otherForm.global} onChange={(ev) => {
            otherChange(ev, 'global');
          }}>全局搜索</Checkbox>
          <PaperClipOutlined onClick={() => onOpenOther({
            key: 'global'
          })}/>
        </div>
        <div>
          <Checkbox checked={otherForm.notify} onChange={(ev) => {
            otherChange(ev, 'notify');
          }}>消息通知</Checkbox>
          <PaperClipOutlined onClick={() => onOpenOther({
            key: 'notify'
          })}/>
        </div>
        <div>
          <Checkbox checked={otherForm.message} onChange={(ev) => {
            otherChange(ev, 'message');
          }}>站内消息</Checkbox>
          <PaperClipOutlined onClick={() => onOpenOther({
            key: 'message'
          })}/>
        </div>
      </XWrapper>

    </>

  );
};

export default App;
