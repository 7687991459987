import { routePath } from "@byk/routes";

export interface XState {
  editUrl: string,
  appId: string,
  tenantId:any,
  iframeDSL: any,
  previewMenuNum: number,
  userPermission: any[],
  linkPannelSet: any,
  linkPannelOffset: any,
  openLinkPannel: boolean
  xLanguageMenu: any,
  xLanguagePage: any,
  xLanguageText: any,
  xLanguage: any,
  xLanguageCurr: string,
  previewUser: any,
  previewPageList:any,
  myTaskSelectWidget: any,
  myTaskMapping: any,
  myTaskMappingStatus: boolean,
  myTaskSelectModal: string,
  widgetPropPopup: any,
  dslNoEventRouter: string[],
  xImportExcel: any
}
const initState: XState = {
  editUrl: '/',
  appId: '',
  tenantId:null,
  iframeDSL: {},
  previewMenuNum: 0,
  userPermission: [],
  linkPannelSet: {
    key: '',
    appid: '',
    iframeid: '',
    actionType: '',
    icon: '',
    topage: '',
    topageid: '',
    toUrl: '',
    topagetype: '',
    linkParams: '',
  },
  linkPannelOffset: {
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    isIcon: false
  },
  openLinkPannel: false,
  xLanguageMenu: [],
  xLanguagePage: [],
  xLanguageText: [],
  xLanguage: [],
  xLanguageCurr: 'default',
  previewUser: {
    role: [],
    appInfo: {},
    currUser: {},
    permission: [],
    menu: [],
    user: {}
  },
  previewPageList: [],
  myTaskSelectWidget: [],
  myTaskMappingStatus: false,
  myTaskMapping: {
    x: 0,
    y: 0,
    key: '',
    path: '',
    isChecked: false,
    widgetId: '',
    widgetLabel: '',
    widgetName: '',
    widgetType: '',
    topWidgetName: '',
  },
  myTaskSelectModal: '',
  widgetPropPopup: {
    x: 0,
    y: 0,
    visible: false,
    propList: [],
    widgetId: '',
    widgetName: '',
  },
  dslNoEventRouter: [`${routePath}/MyTask`, `${routePath}/AppActionManager`],
  xImportExcel: {
    visible: false,
    config: {}
  }
}
export const X_Edit_Url = "X_Edit_url";
export const X_SUBAPPID = "X_SUBAPPID";
export const X_TENANTID = "X_TENANTID";
export const X_IFRAMEDSL = "X_IFRAMEDSL";
export const X_PREVIEW_MENU_NUM = "X_PREVIEW_MENU_NUM";
export const X_USER_PERMISSION = "X_USER_PERMISSION";
export const X_LINKPAGESET = "X_LINKPAGESET";
export const X_OPENLINKPANNEL = "X_OPENLINKPANNEL";
export const X_LINKPANNELOFFSET = "X_LINKPANNELOFFSET";
export const X_LANGUAGE_MENU = "X_LANGUAGE_MENU";
export const X_LANGUAGE_PAGE = "X_LANGUAGE_PAGE";
export const X_LANGUAGE_TEXT = "X_LANGUAGE_TEXT";
export const X_LANGUAGE = "X_LANGUAGE";
export const X_LANGUAGE_CURR = "X_LANGUAGE_CURR";
export const X_PREVIEW_USER = "X_PREVIEW_USER";
export const X_PREVIEW_PAGELIISTS = "X_PREVIEW_PAGELIISTS";
export const X_MY_TASK_SELECT_WIDGET = "X_MY_TASK_SELECT_WIDGET";
export const X_MYTASK_MAPPING = "X_MYTASK_MAPPING";
export const X_MYTASK_MAPPING_STATUS = "X_MYTASK_MAPPING_STATUS";
export const X_WIDGET_PROP_POPUP = "X_WIDGET_PROP_POPUP";
export const X_MYTASK_SELECT_MODAL = "X_MYTASK_SELECT_MODAL";
export const X_IMPORT_EXCEL = "X_IMPORT_EXCEL";
export const X_CURRENT_PAGE_DSL = "X_CURRENT_PAGE_DSL";
export const X_HEADBOX_Z_INDEX = "X_HEADBOX_Z_INDEX";
export default function XReducers(state=initState, action: any) {
  const {type, data} = action;
  switch (type) {
    case X_IMPORT_EXCEL:
      return {
        ...state,
        xImportExcel: {
          ...state.xImportExcel,
          ...data
        }
      }
    case X_MYTASK_SELECT_MODAL:
      return {
        ...state,
        myTaskSelectModal: data
      }
    case X_WIDGET_PROP_POPUP:
      return {
        ...state,
        widgetPropPopup: {
          ...state.widgetPropPopup,
          ...data
        }
      }
    case X_MYTASK_MAPPING_STATUS:
      return {
        ...state,
        myTaskMappingStatus: data
      }
    case X_MYTASK_MAPPING:
      return {
        ...state,
        myTaskMapping: {
          ...state.myTaskMapping,
          ...data
        }
      }
    case X_PREVIEW_PAGELIISTS:
      return {
        ...state,
        previewPageList: data
      }
    case X_PREVIEW_USER:
      return {
        ...state,
        previewUser: data
      }
    case X_LANGUAGE_CURR:
      return {
        ...state,
        xLanguageCurr: data
      }
    case X_LANGUAGE:
      return {
        ...state,
        xLanguage: data
      }
    case X_LANGUAGE_PAGE:
      return {
        ...state,
        xLanguagePage: data
      }
    case X_LANGUAGE_TEXT:
      return {
        ...state,
        xLanguageText: data
      }
    case X_LANGUAGE_MENU:
      return {
        ...state,
        xLanguageMenu: data
      }
    case X_USER_PERMISSION:
      return {
        ...state,
        userPermission: data
      }
    case X_Edit_Url:
      return {
        ...state,
        editUrl: data
      }
    case X_SUBAPPID:
      return {
        ...state,
        appId: data
      }
    case X_TENANTID:
        return {
          ...state,
          tenantId: data
        }
    case X_IFRAMEDSL:
      return {
        ...state,
        iframeDSL: data
      }
    case X_PREVIEW_MENU_NUM:
      return {
        ...state,
        previewMenuNum: state.previewMenuNum+=1
      }
    case X_LINKPAGESET:
      return {
        ...state,
        linkPannelSet: data
      }
    case X_OPENLINKPANNEL:
      return {
        ...state,
        openLinkPannel: data
      }
    case X_LINKPANNELOFFSET:
      return {
        ...state,
        linkPannelOffset: data
      }
    case X_MY_TASK_SELECT_WIDGET:
      return {
        ...state,
        myTaskSelectWidget: data
      }
    case X_CURRENT_PAGE_DSL:
      return {
        ...state,
        currentPageDsl: data
      }
    case X_HEADBOX_Z_INDEX:
      return {
        ...state,
        headBoxZIndex: data
      }
    default:
      return state
  }
}
