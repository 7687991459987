import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

export interface DynamicModel {
  modelData: any,
  queryData: StructuredQuery,
  model: any,
}

const initState: DynamicModel = {
  modelData: {
    properties: []
  },
  queryData: new StructuredQuery({}),
  model: null,
}

export const STORE_KEY_MODEL_DATA_RECORD = "STORE_KEY_MODEL_DATA_RECORD";
export const QUERY_STRUCT = "QUERY_STRUCT";
export const SET_MODEL = "SET_MODEL";

export const setCurDynamicModel = (model: any) => {
  return {
    type: SET_MODEL,
    data: model,
  };
};

export default function syncReducer(state = initState, action: any) {
  //从action对象中获取：type、data
  const {type, data} = action;
  //根据type决定如何加工数据
  switch (type) {
    case SET_MODEL: {
      return {
        ...state,
        model: data
      }
    }
    case STORE_KEY_MODEL_DATA_RECORD:
      return {
        ...state,
        modelData: data
      }
    case QUERY_STRUCT:
      return {
        queryData: data
      }
    default:
      return state
  }
}
