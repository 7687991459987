// @ts-ignore
import _ from "underscore";
import StructuredQuery from "../queries/StructuredQuery";
import {FieldReference, LocalFieldReference} from "@byk/pages/QueryBuilder/lib/metadata/types";
import Field from "@byk/pages/QueryBuilder/lib/metadata/Field";
import {FilterOperator} from "@byk/pages/QueryBuilder/lib/metadata/types/deprecated-types";
import {FK_SYMBOL} from "@byk/pages/QueryBuilder/lib/expressions";
import {isExpressionReference, isFieldReference} from "@byk/pages/QueryBuilder/lib/metadata/references";

function stripId(name: string) {
  return name?.replace(/ id$/i, "").trim();
}

export const BASE_DIMENSION_REFERENCE_OMIT_OPTIONS = [
  "temporal-unit",
  "binning",
];

/**
 * A dimension option returned by the query_metadata API
 */
/* Heirarchy:
 *
 * - Dimension (abstract)
 *   - FieldDimension
 *   - ExpressionDimension
 *   - AggregationDimension
 *   - TemplateTagDimension
 */

/**
 * Dimension base class, represents an MBQL field reference.
 *
 * Used for displaying fields (like Created At) and their "sub-dimensions" (like Created At by Day)
 * in field lists and active value widgets for filters, aggregations and breakouts.
 *
 * @abstract
 */

export default class Dimension {
  _parent: Dimension | null | undefined;
  _args: any;
  _metadata: any | null | undefined;
  _query: StructuredQuery | null | undefined;
  _options: any;
  _fieldIdOrName: any;

  _subDisplayName: string | null | undefined;
  _subTriggerDisplayName: string | null | undefined;

  /**
   * Dimension constructor
   */
  constructor(
    parent: Dimension | null | undefined,
    args: any[],
    metadata?: any,
    query?: StructuredQuery | null | undefined,
    options?: any,
  ) {
    this._parent = parent;
    this._args = args;
    this._metadata = metadata || (parent && parent._metadata);
    this._query = query || (parent && parent._query);
    this._options = options;
  }

  /**
   * Parses an MBQL expression into an appropriate Dimension subclass, if possible.
   * Metadata should be provided if you intend to use the display name or render methods.
   */
  static parseMBQL(
    mbql: any,
    metadata?: any,
    query?: any,
  ): Dimension | null | undefined {
    for (const D of DIMENSION_TYPES) {
      const dimension = D.parseMBQL(mbql, metadata, query);

      if (dimension != null) {
        return Object.freeze(dimension);
      }
    }

    return null;
  }

  parseMBQL(mbql: any): Dimension | null | undefined {
    return Dimension.parseMBQL(mbql, this._metadata, this._query);
  }

  field(): Field {
    return new Field();
  }

  mbql(): FieldReference | null | undefined {
    throw new Error("Abstract method `mbql` not implemented");
  }

  filterOperator(operatorName: string): FilterOperator | null | undefined {
    return this.field().filterOperator(operatorName);
  }

  filterOperators(selected?: any): FilterOperator[] {
    return this.field().filterOperators(selected);
  }

  defaultFilterOperator(): FilterOperator | null | undefined {
    return this.filterOperators()[0];
  }

  /*
  * The temporal unit that is being used to bucket this Field, if any.
  */
  temporalUnit() {
    return this.getOption("temporal-unit");
  }

  /**
   * Is this dimension identical to another dimension or MBQL clause
   */
  isEqual(
    other: any,
  ): boolean {
    if (other == null) {
      return false;
    }

    const otherDimension: Dimension | null | undefined =
      other instanceof Dimension ? other : this.parseMBQL(other);

    if (!otherDimension) {
      return false;
    }

    // assumes .mbql() returns canonical form
    return _.isEqual(this.mbql(), otherDimension.mbql());
  }

  /**
   * Does this dimension have the same underlying base dimension, typically a field
   */
  isSameBaseDimension(
    other: any,
  ): boolean {
    if (other == null) {
      return false;
    }

    const otherDimension: Dimension | null | undefined =
      other instanceof Dimension ? other : this.parseMBQL(other);
    const baseDimensionA = this.baseDimension();
    const baseDimensionB = otherDimension && otherDimension.baseDimension();
    return (
      !!baseDimensionA &&
      !!baseDimensionB &&
      baseDimensionA.isEqual(baseDimensionB)
    );
  }

  isExpression() {
    return false;
  }

  setQuery(query: StructuredQuery): Dimension {
    return this;
  }

  icon(): any {
    return "";
  }

  /**
   * The display name of this dimension, e.x. the field's display_name
   * @abstract
   */
  displayName(): string {
    return "";
  }

  render(): any {
    return this._parent ? this._parent.render() : this.displayName();
  }

  /**
   * Return a copy of this Dimension that includes the specified `options`.
   * @abstract
   */
  withOptions(options: any): Dimension {
    return this;
  }

  /**
   * Return a copy of this Dimension with option `key` set to `value`.
   */
  withOption(key: string, value: any): Dimension {
    return this.withOptions({
      [key]: value,
    });
  }

  getOptions() {
    return this._options;
  }

  /**
   * Get an option from the field options map, if there is one.
   */
  getOption(k: string): any {
    const options = this.getOptions();
    return options?.[k];
  }

  /**
   * Returns the default sub-dimension of this dimension, if any.
   * @abstract
   */
  defaultDimension(
    DimensionTypes: any[] = DIMENSION_TYPES,
  ): Dimension | null | undefined {

    return null;
  }

  /**
   * A shorter version of subDisplayName, e.x. to be shown in the dimension picker trigger (e.g. the list of temporal
   * bucketing options like 'Day' or 'Month')
   */
  subTriggerDisplayName(): string {
    if (this._subTriggerDisplayName) {
      return this._subTriggerDisplayName;
    }

    return "";
  }

  binningStrategy() {
    return this.getBinningOption("strategy");
  }

  getBinningOption(option: any) {
    return this.binningOptions() && this.binningOptions()[option];
  }

  // binning-strategy stuff
  binningOptions() {
    return this.getOption("binning");
  }

  /**
   * Return a copy of this Dimension with any temporal bucketing or binning options removed.
   */
  baseDimension(): Dimension {
    return this.withoutOptions(...BASE_DIMENSION_REFERENCE_OMIT_OPTIONS);
  }


  /**
   * Return the join alias associated with this field, if any.
   */
  joinAlias() {
    return this.getOption("join-alias");
  }

  sourceField() {
    return this.getOption("source-field");
  }

  /**
   * Return a copy of this Dimension with join alias set to `newAlias`.
   */
  withJoinAlias(newAlias: any) {
    return this.withOptions({
      "join-alias": newAlias,
    });
  }

  /**
   * Return a copy of this Dimension with a replacement source field.
   */
  withSourceField(sourceField: any) {
    return this.withOptions({
      "source-field": sourceField,
    });
  }

  /**
   * Return a copy of this Dimension that excludes `options`.
   * @abstract
   */
  withoutOptions(...options: string[]): Dimension {
    return this;
  }

  subDisplayName(): string {
    if (this._subDisplayName) {
      return this._subDisplayName;
    }

    // honestly, I have no idea why we do something totally random if we have a FK source field compared to everything
    // else, but that's how the tests are written
    if (this.sourceField()) {
      return this.displayName();
    }

    return "Default";
  }
}

export const normalizeReferenceOptions = (
  options?: any | null,
): any | null => {
  if (!options) {
    return null;
  }

  // recursively normalize maps inside options.
  options = _.mapObject(options, (val: any) =>
    typeof val === "object" ? normalizeReferenceOptions(val) : val,
  );
  // remove null/undefined options from map.
  options = _.omit(options, (value: any) => value == null);
  return _.isEmpty(options) ? null : options;
};

export class FieldDimension extends Dimension {
  static parseMBQL(
    mbql: any,
    metadata = null,
    query = null,
  ): FieldDimension | null | undefined {
    console.log("FieldDimension--->parseMBQL", query);
    if (isFieldReference(mbql)) {
      return Object.freeze(
        new FieldDimension(mbql[1], mbql[2], metadata, query),
      );
    }
  }

  /**
   * Parse MBQL field clause or log a warning message if it could not be parsed. Use this when you expect the clause to
   * be a `:field` clause
   */
  static parseMBQLOrWarn(
    mbql: any,
    metadata = null,
    query = null,
  ): FieldDimension | null | undefined {
    // if some some reason someone passes in a raw integer ID instead of a proper Field form, go ahead and parse it
    // anyway -- there seems to be a lot of code that does this -- but log an error message so we can fix it.
    if (typeof mbql === "number") {
      console.error(
        "FieldDimension.parseMBQLOrWarn() called with a raw integer Field ID. This is an error. Fixme!",
        mbql,
      );
      return FieldDimension.parseMBQLOrWarn(
        ["field", mbql, null],
        metadata,
        query,
      );
    }

    const dimension = FieldDimension.parseMBQL(mbql, metadata, query);

    if (!dimension) {
      console.warn("Unknown MBQL Field clause", mbql);
    }

    return dimension;
  }

  constructor(
    fieldIdOrName: any,
    options: any = null,
    metadata: any = null,
    query: any = null,
    additionalProperties: any = null,
  ) {
    super(
      null,
      [fieldIdOrName, options],
      metadata,
      query,
      Object.freeze(normalizeReferenceOptions(options)),
    );

    this._fieldIdOrName = fieldIdOrName;

    if (additionalProperties) {
      Object.keys(additionalProperties).forEach(k => {
        // @ts-ignore
        this[k] = additionalProperties[k];
      });
    }

    Object.freeze(this);
  }

  setQuery(query: StructuredQuery): FieldDimension {
    return new FieldDimension(
      this._fieldIdOrName,
      this._options,
      this._metadata,
      query,
      {
        _fieldInstance: null,
        _subDisplayName: this._subDisplayName,
        _subTriggerDisplayName: this._subTriggerDisplayName,
      },
    );
  }

  render(): string {
    let displayName = this.displayName();

    if (this.fk()) {
      const fkDisplayName =
        this.fk() && stripId(this.fk()?.field().displayName() || "");
      if (!displayName.startsWith(`${fkDisplayName} ${FK_SYMBOL}`)) {
        displayName = `${fkDisplayName} ${FK_SYMBOL} ${displayName}`;
      }
    } else if (this.joinAlias()) {
      const joinAlias = this.joinAlias();
      if (!displayName.startsWith(`${joinAlias} ${FK_SYMBOL}`)) {
        displayName = `${joinAlias} ${FK_SYMBOL} ${displayName}`;
      }
    }

    return displayName;
  }

  mbql(): LocalFieldReference {
    return ["field", this._fieldIdOrName, this._options];
  }

  isIntegerFieldId(): boolean {
    return typeof this._fieldIdOrName === "number";
  }

  fieldIdOrName(): string | number {
    return this._fieldIdOrName;
  }

  isStringFieldName(): boolean {
    return typeof this._fieldIdOrName === "string";
  }

  getOptions() {
    return this._options;
  }

  getOption(k: string): any {
    const options = this.getOptions();
    return options?.[k];
  }

  field(): Field {
    let property = this._query?.metaProperty(this._fieldIdOrName);
    if (property) {
      return new Field(property, property.table);
    } else {
      return new Field();
    }
  }

  icon() {
    return this.field().icon();
  }

  displayName() {
    return this.field().displayName();
  }

  withOptions(options: any): FieldDimension {
    if (!options || !Object.entries(options).length) {
      return this;
    }

    console.log("Dimension-->FieldDimension--->withOptions", this._query);
    return new FieldDimension(
      this._fieldIdOrName,
      {...this._options, ...options},
      this._metadata,
      this._query,
      {
        _subDisplayName: this._subDisplayName,
        _subTriggerDisplayName: this._subTriggerDisplayName,
      },
    );
  }

  fk() {
    const sourceFieldIdOrName = this.sourceField();

    if (!sourceFieldIdOrName) {
      return null;
    }

    return new FieldDimension(
      sourceFieldIdOrName,
      null,
      this._metadata,
      this._query,
    );
  }

  temporalUnit() {
    return this.getOption("temporal-unit");
  }

  getMLv1CompatibleDimension() {
    return this.isIntegerFieldId()
      ? this.withoutOptions("base-type", "effective-type")
      : this;
  }
}

/**
 * Expression reference, `["expression", expression-name]`
 */
export class ExpressionDimension extends Dimension {
  _expressionName: any;
  name: any;
  expression: any;

  static parseMBQL(
    mbql: any,
    metadata: any,
    query: any,
  ): ExpressionDimension | null | undefined {
    if (isExpressionReference(mbql)) {
      const [expressionName, options] = mbql.slice(1);
      return new ExpressionDimension(expressionName, options, metadata, query);
    }
  }

  constructor(
    expressionName: any,
    options: any[] = [],
    metadata = null,
    query: any = null,
    additionalProperties = null,
  ) {
    super(
      null,
      [expressionName, options],
      metadata,
      query,
      Object.freeze(normalizeReferenceOptions(options)),
    );
    this._expressionName = expressionName;
    this.name = expressionName;
    this.expression = options;

    if (additionalProperties) {
      Object.keys(additionalProperties).forEach(k => {
        // @ts-ignore
        this[k] = additionalProperties[k];
      });
    }

    Object.freeze(this);
  }

  setQuery(query: StructuredQuery): ExpressionDimension {
    return new ExpressionDimension(
      this._expressionName,
      this._options,
      this._metadata,
      query,
    );
  }

  /**
   * Return a copy of this ExpressionDimension that includes the specified `options`.
   */
  withOptions(options: any): ExpressionDimension {
    // optimization : if options is empty return self as-is
    if (!options || !Object.entries(options).length) {
      return this;
    }

    return new ExpressionDimension(
      this._expressionName,
      {...this._options, ...options},
      this._metadata,
      this._query,
    );
  }
}

const DIMENSION_TYPES: typeof Dimension[] = [
  FieldDimension,
  ExpressionDimension,
  // AggregationDimension,
  // TemplateTagDimension,
];
