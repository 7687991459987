export function setCookie(
  key:any,
  value:any,
  expires = '2122-02-20 16:29:00',
  domain = window.location.hostname,
  path = '/') {
  const time = expires ? new Date(expires) : expires
  document.cookie = `${key}=${value}; expires=${time}; domain=${domain}; path=${path}`
}

export const getCookie = (key:string)=>{
  const Cookie = document.cookie;
  const cookieList = Cookie?.split('; ');
  const cookieKeyList = cookieList?.map(item => {
    return item.split('=')[0]
  })
  if(cookieKeyList&&cookieKeyList.indexOf(key)>0){
    const index = cookieKeyList.indexOf(key);
    return cookieList[index].split('=')[1];
  }
  return '';

}
