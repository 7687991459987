import {FieldFilter, FieldReference} from "@byk/pages/QueryBuilder/lib/metadata/types";
import MBQLArrayClause from "@byk/pages/QueryBuilder/lib/queries/structured/MBQLClause";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";
import {FilterOperator} from "../../metadata/types/deprecated-types";
import Dimension from "@byk/pages/QueryBuilder/lib/metadata/Dimension";
import {hasFilterOptions, isCustom, isFieldFilter, isSegment, isStandard} from "@byk/pages/QueryBuilder/utils/filter";
import Segment from "@byk/pages/QueryBuilder/lib/metadata/Segment";
import {isStartingFrom} from "@byk/pages/QueryBuilder/utils/query-time";
import moment from "moment-timezone";
import {formatDateTimeRangeWithUnit} from "@byk/pages/QueryBuilder/utils/date";

interface FilterDisplayNameOpts {
  includeDimension?: boolean;
  includeOperator?: boolean;
}

export default class Filter extends MBQLArrayClause {
  constructor(mbql: any[],
              index?: number | null | undefined,
              query?: StructuredQuery) {
    super(mbql, index, query);
    Object.assign(this, mbql);
  }

  replace(filter?: any[]): StructuredQuery {
    if (filter != null) {
      return this._query.updateFilter(this._index, filter);
    } else {
      return this._query.updateFilter(this._index, this);
    }
  }

  remove(): StructuredQuery {
    return this._query.removeFilter(this._index);
  }


  operator(opName = this.operatorName()): FilterOperator | null | undefined {
    const dimension = this.dimension();
    return dimension ? dimension.filterOperator(opName) : null;
  }

  operatorName() {
    return this[0];
  }

  /**
   * Returns true if this is a "standard" filter
   */
  isStandard() {
    return isStandard(this);
  }

  /**
   * Returns true if this is a segment
   */
  isSegment() {
    return isSegment(this);
  }

  /**
   * Returns true if this is custom filter created with the expression editor
   */
  isCustom() {
    return isCustom(this);
  }

  /**
   * Returns true for filters where the first argument is a field
   */
  isFieldFilter() {
    return isFieldFilter(this);
  }


  dimension(): Dimension | null | undefined {
    if (this.isFieldFilter()) {
      return this._query.parseFieldReference(this[1]);
    }
  }

  setDimension(fieldRef: FieldReference, useDefaultOperator?: boolean) {
    if (!fieldRef) {
      return this.set([]);
    }
    const dimension: Dimension = this._query.parseFieldReference(fieldRef);
    if (dimension) {
      const operator =
        dimension.filterOperator(this.operatorName()) ||
        (useDefaultOperator && dimension.defaultFilterOperator());

      const filter: Filter = this.set([this[0], fieldRef, ...this.slice(2)]);

      const operatorName = operator && operator.name;
      if (operatorName && filter.operatorName() !== operatorName) {
        return filter.setOperator(operatorName);
      } else {
        return filter;
      }
    }

    return this;
  }

  setArgument(index: number, value: any) {
    return this.set([
      ...this.slice(0, index + 2),
      value,
      ...this.slice(index + 3),
    ]);
  }

  setArguments(values: any[]) {
    return this.set([...this.slice(0, 2), ...values]);
  }

  filterOperators(selected: string): FilterOperator[] | null | undefined {
    const dimension = this.dimension();
    return dimension ? dimension.filterOperators(selected) : null;
  }

  setOperator(operatorName: any) {
    const dimension = this.dimension();
    const operator = dimension && dimension.filterOperator(operatorName);
    // @ts-ignore
    const filter: FieldFilter = [operatorName, dimension && dimension.mbql()];

    if (operator) {
      for (let i = 0; i < operator.fields.length; i++) {
        if (operator.fields[i].default !== undefined) {
          // @ts-ignore
          filter.push(operator.fields[i].default);
        } else {
          // @ts-ignore
          filter.push(undefined);
        }
      }

      if (operator.optionsDefaults) {
        filter.push(operator.optionsDefaults);
      }

      const oldOperator = this.operator();
      const oldFilter = this;

      if (oldOperator) {
        // copy over values of the same type
        for (let i = 0; i < oldFilter.length - 2; i++) {
          const field = operator.multi
            ? operator.fields[0]
            : operator.fields[i];
          const oldField = oldOperator.multi
            ? oldOperator.fields[0]
            : oldOperator.fields[i];

          if (
            field &&
            oldField &&
            field.type === oldField.type &&
            oldFilter[i + 2] !== undefined
          ) {
            filter[i + 2] = oldFilter[i + 2];
          }
        }
      }
    }
    return this.set(filter);
  }

  arguments() {
    return hasFilterOptions(this) ? this.slice(2, -1) : this.slice(2);
  }

  betterDateLabel() {
    const args = this.arguments();
    if (!args.every(arg => typeof arg === "string")) {
      return undefined;
    }
    const unit = this.dimension()?.temporalUnit();
    const isSupportedDateRangeUnit = [
      "day",
      "week",
      "month",
      "quarter",
      "year",
    ].includes(unit);
    const op = this.operatorName();
    const betweenDates = op === "between" && isSupportedDateRangeUnit;
    const equalsWeek = op === "=" && unit === "week";
    if (betweenDates || equalsWeek) {
      // @ts-ignore
      return formatDateTimeRangeWithUnit(args, unit, {
        type: "tooltip",
        date_resolution: unit === "week" ? "day" : unit,
      });
    }
    // @ts-ignore
    const sliceFormat = {
      "hour-of-day": "[hour] H",
      "minute-of-hour": "[minute] m",
      "day-of-month": "Do [day of month]",
      "day-of-year": "DDDo [day of year]",
      "week-of-year": "wo [week of year]",
    }[unit];

    const m = moment(args[0]);
    if (op === "=" && sliceFormat && m.isValid()) {
      return m.format(sliceFormat);
    }
  }

  formattedArguments(): any[] {
    const dimension = this.dimension();
    const operator = this.operator();
    const args = this.arguments();
    console.log("Filters--->formattedArguments", dimension, operator, args);

    return args
      .map((value, index) => [
        value
      ])
      .filter(([value]) => value !== undefined)
      .map(
        (
          [value],
          index, // FIXME: remapping
        ) => value
      )
  }

  /**
   * Returns the display name for the filter
   */
  displayName({
                includeDimension = true,
                includeOperator = true,
              }: FilterDisplayNameOpts = {}) {
    if (this.isSegment()) {
      const segment = this.segment();
      return segment ? segment.displayName() : "未知的分段";
    } else if (this.isStandard()) {
      if (isStartingFrom(this)) {
        includeOperator = false;
      }
      const betterDate = this.betterDateLabel();
      const op = betterDate ? "=" : this.operatorName();
      let a1 = includeDimension && this.dimension()?.displayName();
      let a2 = includeOperator && this.operator(op)?.moreVerboseName;
      let a3 = betterDate ?? this.formattedArguments().join(" ");
      return [
        includeDimension && this.dimension()?.displayName(),
        includeOperator && this.operator(op)?.moreVerboseName,
        betterDate ?? this.formattedArguments().join(" "),
      ]
        .map(s => s || "")
        .join(" ");
    } else if (this.isCustom()) {
      return this._query.formatExpression(this);
    } else {
      return "未知过滤器";
    }
    // let dim = this.dimension();
    // if (dim && dim.field()) {
    //   let fieldName = dim.field().displayName();
    //   let operatorName = this.operator(this.operatorName())?.verboseName;
    //   let value = this[2];
    //   return fieldName + operatorName + value;
    // } else {
    //   return this._query.formatExpression(this);
    // }
  }

  isValid() {
    return true;
  }

  private segment(): Segment {
    return new Segment({});
  }
}
