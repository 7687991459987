import MBQLArrayClause from "@byk/pages/QueryBuilder/lib/queries/structured/MBQLClause";
import StructuredQuery from "@byk/pages/QueryBuilder/lib/queries/StructuredQuery";

export default class Breakout extends MBQLArrayClause {
  constructor(mbql: any[],
              index?: number | null | undefined,
              query?: StructuredQuery) {
    super(mbql, index, query);
    Object.assign(this, mbql);
  }

  longDisplayName() {
    let field = this.query().metaProperty(this[1]);
    let alias = this[2];
    return (alias ? alias + " -> " : "") + field.name;
  }
}
