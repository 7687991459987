import { Table } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Api from "@byk/api/Api";
import _ from "lodash";
import localStorage from "utils/localStorage";
export interface TableProps {
  columns: any,
  api: string,
  pagination?: any,
  initParams?: any,
  firstLoad?: boolean,
  ref?: any,
  scroll?: any,
  callback?: (res?: any) => any,
  formatData?: (res?: any) => any,//将result.result数据进行改造
  reInitData?: (params?: any) => any,
  searchParam?: (params?: any) => any,
  isSavePage?:boolean,
  savePageKey?:any,
  isSearchByOldPage?:boolean,
}

const TableList: React.FC<TableProps> = forwardRef(({
    columns, api, initParams, pagination, scroll, callback,formatData, firstLoad,reInitData,searchParam,isSavePage,savePageKey,isSearchByOldPage }, ref) => {
  useImperativeHandle(ref, () => ({
    getTableList,
    setInitParams,
    setTableData
  }));
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFixDataChange, setIsFixDataChange] = useState(false);
  const [initParamsNew, setInitParamsNew] = useState({});
  const [paginationProps, setPaginationProps] = useState({
    current: pagination ? pagination.currPage : 1,//当前页
    pageSize: pagination ? pagination.pageSize : 10,//每页条数
    onChange: (page: number) => handlePageChange(page), //改变页码的函数
    hideOnSinglePage: false,
    showSizeChanger: false,
  });
  const setInitParams=(_data:any)=>{
    setInitParamsNew(_data);
    setIsFixDataChange(true);
  }
  //查询
  const getTableList = async (params?: any, paginationParams?: any) => {
    let _params = {
      ...params,
      ...initParams,
      currPage: pagination.currPage,
      pageSize: pagination.pageSize,
    };
    if (paginationParams) {
      _params = {
        ..._params,
        ...paginationParams,
      };
    } else {
      _params.currPage = 1;
    }
    _params['queryMode'] = 'allLike';
    setLoading(true);
    if(isSavePage&&isSearchByOldPage==false){
      localStorage.setItem(savePageKey, JSON.stringify(_params));
    }
    if(isSearchByOldPage){
      let p:any=localStorage.getItem(savePageKey);
      if(!_.isEmpty(p)){
        _params=JSON.parse(p);
      }
    }
    let result: any = await Api.get(api, _params);
    setLoading(false);
    if (!result.success) {
      return;
    }
    if (callback != undefined) {
      callback(result);
    }
    let _result=result.result;
    if (formatData != undefined) {
      _result=formatData(result);
    }
    //tableList 数据
    setTableData(_result.map((item: any) => {
      return {
        ...item,
        key: item.id,
      };
    }));
    // 翻页数据
    let _pageParams = {
      ...pagination,
      queryParams: params,
      ...paginationProps,
      total: result.size,
      showTotal: () => (
        <span>总共{result.size}项</span>
      ),
    };
    if (paginationParams) {
      _pageParams = {
        ..._pageParams,
        ...paginationParams,
        current: paginationParams.currPage,
      };
    } else {
      _pageParams.current = 1;
    }
    if(isSearchByOldPage){
      if(_params.currPage!=undefined){
        _pageParams.current=_params.currPage;
        _pageParams.currPage=_params.currPage;
      }
    }
    // _pageParams.current=3;
    // _pageParams.currPage=3;
    setPaginationProps(paginationProps => (_pageParams));
  };
  // 改变页码的回调 page代表页码数 pageSize代表每页条数
  const handlePageChange = (page: number) => {
    let _searchParam={};
    if (searchParam != undefined) {
      _searchParam= searchParam();
    }
    getTableList && getTableList({
      ...pagination.queryParams,..._searchParam
    }, {
      currPage: page,
    });
  };
  useEffect(() => {
    !firstLoad&&getTableList();
  }, [firstLoad]);

  return (
    <Table columns={columns} bordered={true} loading={loading} scroll={scroll} dataSource={tableData} size={"middle"}
           pagination={paginationProps} rowKey={(() => Math.random())}/>
  );
});

export default TableList;
