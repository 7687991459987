export const DATETIME_UNITS = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "quarter",
  "year",
  "hour-of-day",
  "day-of-week",
  "day-of-month",
  "week-of-year",
  "month-of-year",
  "quarter-of-year",
];

export function isRelativeDatetime(value) {
  return Array.isArray(value) && value[0] === "relative-datetime";
}

function isInterval(mbql) {
  if (!Array.isArray(mbql)) {
    return false;
  }
  const [op, num, unit] = mbql;
  return (
    op === "interval" &&
    typeof num === "number" &&
    DATETIME_UNITS.indexOf(unit) > -1
  );
}

export function isStartingFrom(mbql) {
  if (!Array.isArray(mbql)) {
    return false;
  }

  const [op, expr, left, right] = mbql;
  if (
    isRelativeDatetime(left) &&
    Array.isArray(expr) &&
    ((op === "between" && isRelativeDatetime(right)) || op === "=")
  ) {
    const [innerOp, _field, interval] = expr;
    if (innerOp === "+" && isInterval(interval)) {
      return true;
    }
  }

  return false;
}
